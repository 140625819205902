#signup {

    background-color: map-get($earth-colours, 'fabulous');
    z-index: 14;
    .inner {
        padding: 2rem 1rem;
    }

    .cosmos-grid-contained {
        padding: 0;
    }
    .cosmos-cell {
        padding: 0;
    }

    p {
        font-size: 14px;
        line-height: 20px;
        &.small {
            font-size: 12px;
        }
        a {
            text-decoration: underline;
        }
    }

    .splodge {
        position: relative;
        span {
            position:absolute;
            font-family: $font-family-rollerscript-smooth;
            color: white;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: rem-calc(32);
            @include breakpoint(large) {
                font-size:2.25rem;
            }

            @include breakpoint(xlarge) {
                font-size: rem-calc(52);
            }
            width: 100%;
            
        }
    }

    #emailsignupform {
        input[type=email] {
            background-color: rgba(206,202,197, 0.35);
            border-radius: 4px;
            height:48px;
            margin-right: 1rem;
            color:black;
            border: none;
            box-shadow: none;
            font-size: 16px;
            position: relative;
            &::placeholder {
                color: black;
                opacity: 1; /* Firefox */
            }
        }
        .error {
            color: map-get($earth-colours, 'visionary');
            &:before {
                content: '! ';
            }
        }

        .button {
            background-color: map-get($earth-colours, 'visionary');
            border-radius: 4px;
            height:48px;
            font-weight: normal;
            text-transform: none;
        }
    }
    img.sample {
        width: 171px;   
    }
}