.mini-wishlist {
    position: relative;
}


.mini-wishlist-holder {
    .ok {
        position: relative;
        background-image: url('/assets/images/icons/m-close.svg');
        background-size: 16px,16px,contain;
        position: absolute;
        height: 16px;
        width: 16px;
        background-repeat: no-repeat;
        padding: 1.5rem;
        display: inline-block;
        background-position: 50%;
        left: 0;
        right: 0;
        margin: auto;   
        cursor: pointer;     
    }

    .paint-button {
        /*
        display: inline-flex;
        align-items: center;*/
        display: inline-block;

        //height: 3.5em;
        font-weight: 400;
        font-style: normal;
        // The SVG is added as an SVG background image

        background-image: url('/assets/images/buttons/palette/circle/neutral-fabulous.svg');
        p {
            color: #424242 ;
            padding-top: 3.5rem;
            text-align: center;
        }

        a {
            color: #424242 ;
            text-decoration: underline;
        }

        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        padding: 2rem;
        height: 275px;
        width: 275px;
        color:white;

    }


    position: absolute;
    top: -16px;
    right: -31px;

        @include breakpoint(large) {
            top: -90px;
            right: -15px;
        }

    
    
    //width: 500px;

    z-index: 600;
    padding: 0 1rem 1rem;

    
}