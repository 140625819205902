.social {
    &.follow {
        background-color: #F2F2F2;
        ul {

            @include breakpoint(large) {
                &:before {
                    content: 'Follow us';
                    line-height: 66px;
                    font-size: 16px;
                    color: #424242;
                    font-weight: 500;
                    padding-right:1rem;
                }
            }
            margin: 29px;
            li a {
                padding: 0.4rem;
                @include breakpoint(large) {
                    padding: 0.7rem;
                }
                img {
                    height: 34px;
                    width: 34px;
                    @include breakpoint(large) {
                        height: 47px;
                        width: 47px;
                    }
                }
            }
        }
    }
    &.share {
        ul {
            @include flex-align('center');
            margin-bottom: 1rem;
            @include breakpoint(medium) {
                float:right;
                margin-bottom: 0;
            }
            &:not(.wpbtn) {
            @include breakpoint(large) {
                @include flex-align('right');
                &:before {
                    content: 'Share me';
                    line-height: 44px;
                    font-size: 16px;
                    color: #DD8F55;
                    font-weight: 500;
                    padding-right:1rem;
                }
            }
        }
            //margin: 5px 15px;
            li a {
                padding: 0.4rem;
                img {
                    height: 34px;
                    width: 34px;
                }
                border: none;
            }
            &.article {
                float:none;
                @include breakpoint(large) {
                    @include flex-align('center');
                    &:before {
                        content: '';
                        padding: 0;
                    }
                    &:after {
                        content: '\ASHARE THIS';
                        display: block;
                        line-height: 44px;
                        font-size: 16px;
                        color: $body-font-color;
                        font-weight: 500;
                        width: 100%;
                    }
                }
                li a {
                    padding: 0.4rem;
                    img {
                        height: 47px;
                    }
                    border: none;
                }
            }
        }        
    }
}