.cosmos-accordion {
    .cosmos-cell {
        padding: 0;
    }
    margin: 0 auto 1rem;
    h2 {
        font-family: $font-family-matter;
        color: black;
        letter-spacing: normal;
        font-weight: 400;
        line-height: 1.4rem;
        font-size: 1.4rem;
        @include breakpoint(large) {
            font-size: rem-calc(32px);
            line-height: rem-calc(48px);
        }
        scroll-margin-top: rem-calc(60px);
        @include breakpoint(large) {
            scroll-margin-top: rem-calc(150px);
        }
    }
    
    h3 {

        a {
            color:  map-get($vibrant-colours, 'experimental');
            &:hidden, &:visited, &:hover {
                color:  map-get($vibrant-colours, 'experimental');
            }
            scroll-margin-top: rem-calc(60px);
            @include breakpoint(large) {
                scroll-margin-top: rem-calc(150px);
            }
            
        }

        position: relative;
        cursor: pointer;
        border-bottom: solid 1px #A3A3A3;
        padding: 1rem 1rem 1rem 0;

        font-size: 1.1rem !important;
        @include breakpoint(large) {
            font-size: rem-calc(35px);
        }

        &:before {
            content: url(/assets/images/icons/prod_info_minus_icon.svg);
            position: absolute;
            top: 50%;
            right: 1rem;
            margin-top: -0.75rem;
            
        }
        & + div {
            -webkit-transform: scaleY(1);
            -o-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
            position: relative;
            opacity: 1;
        }
        &.closed {
            & + div {
                -webkit-transform: scaleY(0);
                -o-transform: scaleY(0);
                -ms-transform: scaleY(0);
                transform: scaleY(0);
                max-width: 70vw;
                position:absolute;
                opacity: 0;
            }
            &:before {
                content: url(/assets/images/icons/prod_info_plus_icon.svg);
                margin-top: -.5rem;
            }
        }

    }
    .paint-button-container {
        a {
            background-image: url('/assets/images/buttons/palette/vibrant-experimental.svg');;
        }
    }
    div {
        margin: 0;
        
        -webkit-transform-origin: top;
        -o-transform-origin: top;
        -ms-transform-origin: top;
        transform-origin: top;
    
        -webkit-transition: -webkit-transform 0.2s ease-out;
        -o-transition: -o-transform 0.2s ease;
        -ms-transition: -ms-transform 0.2s ease;
        transition: all 0.3s ease;


    }
}