$breakpoints: (
	small: 0,
	medium: 667px,
	large: 950px,
	xlarge: 1201px
);
$breakpoint-classes: (small medium large xlarge);

$foundation-palette: (
  primary: map-get($vibrant-colours, 'ethically-concious'),
  secondary: map-get($vibrant-colours, 'discerning'),
  success: #3adb76,
  warning: #ffae00,
  alert: #ec5840,
);

$header-styles: (
  small: (
    'h1': ('font-size': 36),
    'h2': ('font-size': 28),
    'h3': ('font-size': 25),
    'h4': ('font-size': 20),
    'h5': ('font-size': 16),
    'h6': ('font-size': 14)
  )  ,  
  medium: (
    'h1': ('font-size': 36),
    'h2': ('font-size': 28),
    'h3': ('font-size': 25),
    'h4': ('font-size': 20),
    'h5': ('font-size': 16),
    'h6': ('font-size': 14)
  )  ,
  large: (
    'h1': ('font-size': 48),
    'h2': ('font-size': 36),
    'h3': ('font-size': 28),
    'h4': ('font-size': 25),
    'h5': ('font-size': 20),
    'h6': ('font-size': 16)
  ),

);

$header-font-family: $font-family-rollerscript-smooth;
$header-color:#A3A3A3;
$body-font-family: $font-family-matter;
$body-font-color: #424242;
$body-background-color: white;
$global-width:1440px;
$global-font-size: 16px;

$anchor-color: $body-font-color;
$anchor-color-hover: $body-font-color;
$anchor-text-decoration-hover: underline;
$print-hrefs: false;

$breadcrumbs-margin: 1rem 0;
$breadcrumbs-item-uppercase: false;
$breadcrumbs-item-font-size: 14px;
$breadcrumbs-item-color: #A3A3A3;
$breadcrumbs-item-separator-color:  #C1C1C1;
$breadcrumbs-item-color-current: $breadcrumbs-item-color;
$breadcrumbs-item-color-disabled: lighten($breadcrumbs-item-color, 20%);
$breadcrumbs-item-margin: 0.5rem;

$accordionmenu-arrows: false;
$form-label-font-size: rem-calc(16);


$button-radius: 4px;
$button-padding: 0.85em 2em 1.1em;

body { font-size: $global-font-size; }

$offcanvas-vertical-sizes: (
    small: 100vh,
    medium: 100vh,
);
$offcanvas-inner-shadow-size: 0;

$accordion-background: transparent;
$accordion-content-border: none;

$input-placeholder-color: RGBA(66,66,66,0.41);

@import "~foundation-sites/scss/foundation";
@include foundation-global-styles;