form, #checkout {

    fieldset {
        padding: 0;
    }

    p {
        margin-bottom: 6px;

        .field {
            span {
                display: inline-block;
                margin: 0.5rem 0;
            }
        }
        &.ticked {
            &:before {
            content: url(/assets/images/icons/tick-bullet.svg);
            padding-right: 0.4rem;
            }
        }
    }

    .error {
        color: map-get($vibrant-colours, 'experimental');
    }

    a.remove {
        color: map-get($vibrant-colours, 'creative');
        text-decoration: underline;
    }

    div.holder {
        position: relative;
        p.error {
            position: absolute;
            top: -2px;
            right: 5px;
            font-size: 14px;
            &:before {
                content: '! ';
            }
        }
    }

    [type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text],[type=email], [type=time], [type=url], [type=week], textarea, select {
        border: solid 1px #D3D3D4;
        border-radius: 4px;
        height: 48px;
        margin-bottom: 0;
        


        .validation-error {
            .parent:after {
                content: '!';
            }
            //background-color: map-get($earth-colours, 'lovable')
        }
    }

    input[readonly] {
        border: none;
        box-shadow: none;
        background-color: transparent;
    }

    .search-input-container {
        position: relative;
        .search-input {
            margin-bottom: 0 !important;
        }
        .search-input-list {
            position: absolute;
            border: solid 2px #eee;
            border-top: none;
            background-color: white;
            z-index: 1;
            width: 100%;
            max-height: 192px;
            overflow-y: scroll; 
            p {
                margin-bottom: 0;
                padding: 0.2rem 0.5rem;
                cursor: pointer;
                &:hover {
                    font-weight: 500
                }
            }
        }
        select {
            margin-top:7px;
        }
    }
/*
    .postcodelist {
        border: solid 2px #eee;
        border-top: none;
        p {
            padding: 0.2rem 0.5rem;
            cursor: pointer;
            &:hover {
                font-weight: 500
            }
        }
    }*/

    .form-post-button {
        @include button();
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        height: 48px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding-left:2rem;
        padding-right: 2rem;
    }

    select { 
        color:$input-placeholder-color;
        
        &.entered {
            color: $black;
        }

        option[value=""][disabled] {
            display: none;
        }
        option {
            color: $black;
        }
        option[disabled] {
            color:$input-placeholder-color;
        }        
    }

    .radio {
        label {
            @include breakpoint(large) {
            display: inline-block;
            padding-right: 6px;
            }
        } 
    }

    /* Customize the label (the container) */
    .radio-container {
        display: inline-block;
        position: relative;
        margin-bottom: 0;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 39px;
        padding-left: 31px;
        padding-right: 16px;
        /* Hide the browser's default radio button */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
  
        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border-radius: 50%;
            border: solid 2px #5B5B5B;
        }
  
        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
            background-color: #ccc;
        }
        
        /* When the radio button is checked, add a blue background */
        input:checked ~ .checkmark {
            background-color:  #5B5B5B;
        }
        
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        /* Show the indicator (dot/circle) when checked */
        input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the indicator (dot/circle) */
        .checkmark:after {
            top: 3px;
            left: 3px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: white;
        }
    }

    .checkbox {
        margin-bottom: 0.7rem;
        input[type='checkbox'], input[type='radio'] { display: none; }
        label {
            position: relative;
            display: table;
            width: 100%;
            line-height: 19px;
            min-height: 28px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0.6rem;
            @include flex-align(left, top);
            display: flex;
            &:before {
                display: block;
                top: 0;
                left: 0;
                border: solid 1px #c9c9c9;
                border-radius: 4px;
                content: "\00a0";
                width: 20px;
                height: 20px;
                font-size: 1.2rem;
                text-align: center;
                color: white;
                background-color: white;
                position: relative;
                line-height: .8rem;
                margin-right: .5rem;
            }
            span.label-text {
                display: table-cell; 
                color: $body-font-color;
                width: 100%;
                padding: 0;
                line-height: 19px;
                margin: 0;
                span {
                    margin: 0;
                }
            }
        }
        input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
            &:before {
                @extend %fa-icon;
                @extend .fas;
                content: fa-content($fa-var-check);
                background-color: map-get($vibrant-colours, 'creative');
                border: solid 1px map-get($vibrant-colours, 'creative');
                font-size: 10px;
                padding: 4px 0;
            }
        }
        .info-icon {
            background: url('/assets/images/icons/information_ICN-2.svg') 85% 0 no-repeat;
            background-size: 20px 20px;
            width: 27px;
            height: 27px;
            border-bottom: none;
            margin: 0;
            float: right;
            cursor: pointer;
        }
        .info-bubble {
            //paint background for tooltip
            background-image: url('/assets/images/buttons/palette/circle/neutral-fabulous.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 171px 171px;
            //padding: 2rem;
            height: 171px;
            width: 171px;
            position: absolute;
            top: -2rem;
            right: 0;
            @include breakpoint(large) {
                right: -7rem;
            }
            
            z-index: 3;
            font-size: 12px;
            line-height: 14px;
            text-align: center;
            @include flex-align(center, middle);
            display: flex;
            padding: 0.5rem 1rem 0
        }
    }


    label {
        font-size: 14px;
        margin-bottom: 1rem;
    }

    .addressChooser {
        margin-top:6px;
    }

}

.paint-tip {
    background-image: url('/assets/images/buttons/paint-circle-green.svg');
}

.autocomplete-suggestion {
    cursor: pointer;
}

.autocomplete-suggestions {
    border: 1px solid #999;
    background: #FFF;
    overflow: auto;
    padding: 5px;
}