#checkout {
    #basket {
        padding-bottom: 0;
        .footer {
            border-bottom: none;
        }
        .button {
            padding: 0.9rem 2rem 1.1rem;
        }
        h2 {
            border-bottom: none;
        }
    }
    div.collapsible {
        padding-bottom: 4rem;
    }
    div.foot {
        padding: 0 5rem;
    }
    h1 {
        @include breakpoint(large) {
        margin-top: -1.9rem;
        }
    }
    h2 {
        color: $body-font-color;
        font-family: $font-family-matter;
        font-weight: 500;
        font-size: rem-calc(24);
        border-bottom: solid 1px #D3D3D4; 
        margin-bottom: 1.5rem;
        line-height: 60px;
    }
    h3 {
        color: $body-font-color;
        font-family: $font-family-matter;
        font-weight: 500;
        font-size: rem-calc(18);
        margin: 1rem 0;
    }
    h4 {
        color: $body-font-color;
        font-family: $font-family-matter;
        font-weight: 500;
        font-size: rem-calc(20);
        margin-bottom: 1rem;
    }    
    h5 {
        font-family: $font-family-matter;
        font-weight: 500;
        margin-bottom: 1rem;
        font-size:1rem;
        color: $body-font-color;
    }
    .button {
        background-color: map-get($vibrant-colours, 'ethically-concious');
        
        //font-weight: 500;
        letter-spacing: 0.5px;
        padding: 0.9rem 2rem 1.1rem;
        text-transform: none;
        margin-bottom: 0;
        
        &.view {
            background-color: #5B5B5B !important;
        }
        &.wide {
    
            @include breakpoint(medium) {
                min-width: 17rem;
            }

        }
    }
    .content {
        @include breakpoint(medium) {
            padding: 0 2rem;
        }
        @include breakpoint(large) {
            padding: 0 5rem;
        }
        a {
            text-decoration: underline;
        }
        a.button {
            text-decoration: none;
        }
    }
    form {
        .search-input-container {
            margin-bottom: 6px;
        }
    }
    [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
        background-color: #F7F7F7;
    } 

    .title {
        font-weight: 500;
    }
    p.bigup {
        font-size: rem-calc(20);
        line-height: rem-calc(28);
        margin-bottom: 2rem;
    }
    p.extra {
        &:before {
            content: url('/assets/images/icons/tick-bullet.svg');
            padding-right: 0.4rem;
        }
    }
    ul.checked {
        list-style-image: url('/assets/images/icons/tick-bullet.svg');
    }
    p.checkout-total {
        margin: 1rem 0 2rem;
    }
    p.checkout-notice {
        margin-bottom: 1.5rem;
        .checkbox a {
            font-weight: 500;
            text-decoration: underline;
        }
        .label-text {
            font-size: 1rem;
        }
    }
    .statement {
        font-size: 14px;
        margin: 1rem 0;
    }
    .rendili-tabs {
        margin: 0 0 2px 0;
        border-top: solid 1px #D3D3D4;
        border-bottom: solid 1px #D3D3D4;
        @include breakpoint(large) {
            width: 100vw;
            margin-left: calc((100% - 100vw) / 2);
        }

        li {
            display: inline-block;
            padding: 0.5rem 0.2rem;
            @include breakpoint(large) {
                padding: 0.5rem 1rem;
            }
            cursor: pointer;
            &:after {
                content: '|';
                color: #D3D3D4;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                @include breakpoint(large) {
                    padding-left:1.5rem;
                    padding-right:0;
                }
            }
            &:last-of-type {
                &:after {
                    content: '';
                }
            }
            &.active {
                color: map-get($vibrant-colours, 'ethically-concious');
            }
        }
    }
}
