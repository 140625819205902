.productlist {
    h2 {
        font-family: $font-family-rollerscript-smooth;
        font-weight: normal;
        //font-size:48px;
        @include breakpoint(small only) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        @include breakpoint(medium) {
            padding: 0.5rem 0 1rem;
        }
        &.depth-2 {
            font-size: 2rem;
            @include breakpoint(medium) {
                font-size: rem-calc(38);
            }
            
            line-height: 50px;
            letter-spacing: 0.4px;

        }
        &.depth-3 {
            font-family: $font-family-recife-display;
            font-size: 22px;
            @include breakpoint(medium) {
            font-size: 26px;
            }
            line-height: 34px;
            letter-spacing: 0.2px;
        }        
        &.depth-4 {
            font-family: $font-family-recife-display;
            //font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.2px;
            font-size: 1.6rem;
        }     
        &.depth-5 {
            font-family: $font-family-recife-display;
            //font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.2px;
            font-size: 1.2rem;
        }                 
    }
    h3 {
        //padding: 1rem 0;
        &.depth-3, &.depth-4, &.depth-5  {
            font-family: $font-family-matter;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #424242;
            @include breakpoint(large) {
                font-size: 16px;
            }
        }
        &.depth-4 {
            display: inline;
        }
     
    }   
    div.category {
        .cosmos-cell {
            padding-left:0 !important;
            padding-right:0 !important;
        }
        p {
            padding-left:0;
            padding-right:0;
            @include breakpoint(small only) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
    .button {
        letter-spacing: 0.5px;
        font-size: 14px;
        text-align: center;
        @include flex-align('center', 'middle');
        border-radius: 4px;
        //padding: .85em 0.8rem;
        height: 48px;
        padding-left: 2rem;
        padding-right: 2rem;
    } 
    .cosmos-cell {
        padding: 0;
    }
    .products {
        @include breakpoint (small only) {
            @include xy-grid;
            @include xy-grid-layout(2, '.product')
        }
        @include breakpoint (medium) {
            @include xy-grid;
            @include xy-gutters(-20px, 'margin');
        }
        @include breakpoint (large) {
            &.filter-shown .product {
                width: 260px;
            }
        }
    }
    .product {
        margin-bottom: 1rem;

        @include breakpoint (medium) {
            @include xy-cell-base(218px);
            @include xy-gutters(20px, 'margin');
            width: 218px;
            transition: width 1s;
        }
        
        a {
            display: block;
        }
        img {
            width: 100%;
            max-width:260px;
        }
        .more {
            font-family: $font-family-rollerscript-smooth;
            color: $header-color;
            font-size: 2rem;
            text-align: center;
            padding-top:2rem;
            padding-bottom: 2rem;
        }
        /*
        .blank {
            display: inline-block;
            width: calc(100% - 14px);
            max-width:235px;
            background: #eee;
            margin: 0.16rem 0 6px 0;
            position: relative;
            border-radius: 2px;
            box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
            &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
            &:before {
                @extend %fa-icon;
                @extend .fas;
                content: fa-content($fa-var-ellipsis-h);
                font-size: 4rem;
                color: #ccc;
                position: absolute;
                text-align: center;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
            }
        }*/
        .text-container {
            display: block;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .img-container {
            display: block;
            position: relative;
            padding-bottom: 0.7rem;
            text-align: center;
        }

            &.new {
                .img-container {
                &:before {
                    display: block;
                    content: 'NEW';
                    color: white;
                    font-size: 12px;
                    letter-spacing: 0.3px;
                    background-image: url('/assets/images/icons/new_paint_circle.svg');
                    background-repeat:  no-repeat;
                    background-size: 49px 48px;
                    height: 49px;
                    width: 48px;
                    position: absolute;
                    bottom: 2px;
                    margin:auto;
                    left:0;
                    right:0;
                    padding: 14px 13px;
                }
            }
        }            
        .sku {
            color: #A3A3A3;
            font-size: 14px;
            display: inline;
        }
        .show-more {
            border: solid 1px #ccc;
            text-align: center;
            position: relative;
        }

    }
    hr {
        margin: 3rem auto 1.42857rem auto;
    }
}

#product-buttons, #runner-buttons {
    @include breakpoint(large) {
        padding-top:1px;
    }
    .cell {
        @include breakpoint(small only) {
            &:nth-child(odd) {
                padding-right: 3px;
            }
            &:nth-child(even) {
                padding-left: 3px;
            }
        }
        @include breakpoint(medium only) {
            &:nth-child(2) {
                padding-right: 6px;
                padding-left: 6px;
            }
            &:nth-child(5) {
                padding-right: 6px;
                padding-left: 6px;
            }                
        }  

    }

    a.button {

        margin-bottom: 6px;
        letter-spacing: 0.4px;
        font-size: 14px;
        text-align: left;
        display: flex;
        @include flex-align('left', 'middle');
        border-radius: 4px;
        padding: .85em 0.8rem;
        height: 48px;

        &.sample {
            background-color: map-get($neutral-colours, 'ethically-concious');
            &:before {
                content: url(/assets/images/icons/AF_Sample_ICN.svg);
                //text-align: left;
                width: 35px;
                display: inline-block;
                margin-right: 10px;
            }
            &:hover {
                background-color: scale-color(map-get($neutral-colours, 'ethically-concious'), $lightness: -15%);
            }
        }
        &.cnc {
            background-color: map-get($neutral-colours, 'visionary');
            &:before {
                content: url(/assets/images/icons/AF_Click_Collect_icn.svg);
                width: 35px;
                display: inline-block; 
                margin-right: 10px;             
            }          
            &:hover {
                background-color: scale-color(map-get($neutral-colours, 'visionary'), $lightness: -15%);
            }              
        }        
        &.retailer {
            background-color: map-get($neutral-colours, 'discerning');
            &:before {
                content: url(/assets/images/icons/AF_Find_Retailer_icn.svg);
                width: 35px;
                display: inline-block; margin-right: 10px;             
            }          
            &:hover {
                background-color: scale-color(map-get($neutral-colours, 'discerning'), $lightness: -15%);
            }              
        } 
        &.mmar {
            background-color: map-get($vibrant-colours, 'creative');
            &:before {
                content: url(/assets/images/icons/AF_MMAR_icn.svg);
                width: 35px;
                display: inline-block;      
                margin-right: 10px;        
            }          
            &:hover {
                background-color: scale-color(map-get($vibrant-colours, 'creative'), $lightness: -15%);
            }              
        }
        &.smmr {
            background-color: map-get($earth-colours, 'creative');
            &:before {
                content: url(/assets/images/icons/AF_SMMR_icn.svg);
                width: 35px;
                display: inline-block; 
                margin-right: 10px;             
            }          
            &:hover {
                background-color: scale-color(map-get($earth-colours, 'creative'), $lightness: -15%);
            }              
        }   
        &.wishlist {
            background-color: map-get($earth-colours, 'lovable');
            &:before {
                content: url(/assets/images/icons/AF_Wishlist_icn.svg);
                width: 35px;
                display: inline-block; 
                @include flex-align('left', 'middle');        
                //margin-right: 10px;     
            }          
            &:hover {
                background-color: scale-color(map-get($earth-colours, 'lovable'), $lightness: -15%);
            }              
        }   
    }

}

#productdetail {
    .breadcrumbs {
        margin-left: 0;
        //margin-top:0;
        line-height: 3.5rem;
        @include breakpoint(medium only) {
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
        @include breakpoint(large) {
            margin-top: 0.6rem;
            margin-bottom: 0.5rem;
        }        
        li {
            line-height: 1.5rem;
        }
        a {
            color: #c1c1c1;
            display: inline-block;
        }
    }
    .main {
        margin-bottom: 1rem;
        .desc {
            a:not(.button) {
                text-decoration: underline;
            }
            .intro {
                //height: 60px;
                overflow: hidden;
            }
            .showmore, .showless {
                display: block;
                padding: 0 0 1rem;
                text-decoration: underline;
                color: #c1c1c1;
            }
            @include breakpoint(large) {
                margin-right:0.5rem;
            }
            @include breakpoint(xlarge) {
                margin-right:1rem;
            }
        }
    }
    .cosmos-panel {
        padding-left: 0;
        padding-right: 0;
    }
    .cosmos-cell {
        padding: 0;
    }

    h1 {
        font-family: $font-family-recife-display;
        font-size: 18px;
        line-height: 21px;
        font-weight: 600;   
        text-align: left;
        @include breakpoint(large) {
            font-size: 24px;
            line-height: 32px;
        }
    }
    .price {
        font-size: 16px;
        line-height: 18px;
        margin-top: 1rem;
        @include breakpoint(large) {
            font-size: 18px;
            //line-height: 28px;
            
        }       
        &.trade {
            color: map-get($vibrant-colours, 'creative');
        } 
    }

    .imagewish {
        position:absolute; top:13px; right:29px; z-index:1;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: map-get($vibrant-colours, 'lovable');
        padding: 8px 4px 4px 5px;
        cursor: pointer;
        @include breakpoint(small only) {
            right: 25px;
        }
    }



    
    #pricing-grid {
        position: relative;
        margin-bottom: 1rem;
        form {
            border: solid 1px #D7D7D7;
            border-radius: 4px;
            padding-left: 0.2em;
            padding-right: 0.2em;
           
            @include breakpoint(large) {
                margin-left: 4px;
            }
            &.buynow {
                padding-bottom: 41px;
                label {
                    margin-bottom: 7px;
                }
            }
        }
        .cell {
            padding-left: 5px;
            padding-right: 5px;            
            @include breakpoint(large) {
                padding-left: 1px;
                padding-right: 0;
            }
        }
        .formtitle {
            line-height: 47px;
            font-weight: 500;
            text-align: center;
            @include breakpoint(large) {
                margin-bottom: 6px;
                text-align: left;
            }
        }  
        label {
            line-height: 29px;
            height: 48px;
            margin: 1px 0 4px;
            @include breakpoint(large) {

            }

        }
        input[type=number] {
            //margin-bottom: 7px;
            height: 48px;
            border-radius: 4px;
            box-shadow: none;

        }
        input[type=text] {
            //margin-bottom: 7px;
            height: 48px;
            border-radius: 4px;
            box-shadow: none;

        }        
        button {
            background-image: url('/assets/images/buttons/Buy_now_circle_btn.svg');
            background-size: 84px 83px;

            background-repeat: no-repeat;
            background-position: center center;

            width: 84px;
            height: 83px;

            text-transform: uppercase;
            color: white;
            font-weight: 500;
            letter-spacing: 0.4px;
            font-size: 12px;
            cursor: pointer;
            display: block;
            position: absolute;
            bottom: -34px;
            left: 0;
            right: 0;
            margin: auto;

        }
    }

    #images {
        .orbit-bullets  {
            &.preview {
                button {
                    width: unset;
                    height: unset;
                    margin: unset;
                    border-radius: 0;
                }
            }
        }
    }


   
}

#productinfo {

    h2 {
        font-family: $font-family-rollerscript-smooth;
        font-weight: normal;
        color: #5B5B5B;
        text-align: center;
        font-size: 32px;
        padding-top: 60px;
        padding-bottom: 1rem;
        @include breakpoint(large) {
            font-size: 48px !important;
            padding-top: 4rem;

        }
    }    
    h3 {
        font-family: $font-family-matter;
        font-weight: 500;
        color: #424242;
        font-size: 18px;
        line-height: 24px;
        border-bottom: solid 1px #A3A3A3;
        padding-bottom: 1rem;

        @include breakpoint(large) {
            font-size: 24px;
            line-height: 24px;
        }            
    }
    ul {
        list-style-type: none;
    }
    margin-bottom: 2rem;
    .accordion {
        width: 100%;
        background: transparent;
    }
    .accordion-content {
        background: transparent;
    }

    .accordion-item {

        .accordion-title {
            background: transparent;
            &:hover {
                background: transparent;
            }
            &:before {
                top: 40%;
                content: url('/assets/images/icons/prod_info_plus_icon.svg');
            }
        }

        &.is-active {
            .accordion-title {
                background: transparent;
                &:hover {
                    background: transparent;
                }
                &:before {
                    content: url('/assets/images/icons/prod_info_minus_icon.svg');
                }
            }            
            
        }


    }


    p.check {
        &:before {
            color: #DD8F55;
            padding-right: 1rem;
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-check);
        }
    }
    .cell {
        font-size: 14px;
        line-height: 24px;
        @include breakpoint(large) {
            font-size: 16px;
            line-height: 28px;
        }
    }


    .title {
        font-family: $font-family-matter;
        font-weight: 500;   
    }
    a {
        font-family: $font-family-matter;
        font-weight: normal;   
    }
    background-color: map-get($neutral-colours, 'fabulous');
    padding-bottom: 4rem;
    margin-bottom: 0;
}

#productguides {
    padding: 61px 0;
    background-color: #ECECEC;
    h2 {
        font-family: $font-family-rollerscript-smooth;
        font-weight: normal;
        font-size: 28px;
        text-align: center;
        margin-bottom: 2rem;
        color: #424242;
        @include breakpoint(large) {
            font-size: 48px;
        }
    }
    .button {
        background-color: #5B5B5B;
        border-radius: 4px;
        //margin-bottom: 0;
        &.alt {
            background-color: map-get($vibrant-colours, 'ethically-concious');
        }
    }
    .item {
        img:not(.icon) {
            border: solid 0.5rem white;
        }
        max-width: 280px;
        margin: auto;
    }
}

#productimages {

    .slick-slider {
        visibility: hidden;
        margin-bottom:0;
        &.slick-initialized {
            display: block;
        }
        &.main {
            .slick-track {
                display: flex !important;
            }
            
            .slick-slide {
                height: inherit !important;
                & > div {
                    height: calc(100% - 6px);
                    div.video-slide {
                        height: 100%;
                        background:black;
                        .flex-video {
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }           
            img {
                @include breakpoint(large) {
                    height: 590px;
                    object-fit: cover;
                }
            }
            .custom_images {
                img {
                    object-fit: none;
                    height: 32px;
                }
            }
        }



        &.slider-nav {
            //visibility: hidden;
            cursor: pointer;
            .slick-track {
                margin-right: -2px;
            }
            .slick-slide {
                padding-right: 4px;
            }
            @include breakpoint(medium) {
                &.slick-initialized {
                    //display: block;
                }
                .slick-prev {
                    left: -25px;
                    top: 25%;
                    &:before {
                        content: url('/assets/images/icons/PPP_arrow_left.svg');
                        height: 26px;
                        width: 14px;
                    }
                }
                .slick-next {
                    right: -25px;
                    top: 25%;
                    &:before {
                        content: url('/assets/images/icons/PPP_arrow_right.svg');
                        height: 26px;
                        width: 14px;
                    }
                }
            }
            img {
                width: 100%;
                opacity: 0.5;
            }


        }

        &.loaded {
            visibility: visible;
        }
    }
    .slick-dots {
        bottom: 20px; 
        li {
            button {
                width: 0.7rem;
                height: 0.7rem;
                margin: 0.5rem 0.3rem;
                border-radius: 50%;
                background-color: #A3A3A3;
                &:before {
                    content: '';
                }
            }
            &.slick-active {
                button {
                    background-color: white;
                }
            }
        }

    }

    .videothumb {
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            background-color: rgba(255,255,255,0.5);
        }
        &:after {
            position: absolute;
            content: url(/assets/images/icons/AF_Play_Video_Button.svg);
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%); 
        };
        img {
            opacity: 1;
        }
    }
}

.star {
    #productimages {
        .slick-slider {
            &.main {
       
                img {
                    //@include breakpoint(large) {
                        width: 100%;
                        object-fit: cover;
                    //}
                }
                .custom_images {
                    img {
                        object-fit: none;
                        height: 32px;
                    }
                }
            }
        }
    }
}

//recently viewed - also like
.productlinks {
    margin: 2rem 0;
    h2 {
        font-family: $font-family-rollerscript-smooth;
        font-weight: normal;
        font-size: rem-calc(28);
        text-align: center;
        margin-bottom: 0;
        @include breakpoint(large) {
            font-size: 48px;
        }
        &.like {
            color: map-get($earth-colours, 'creative')
        }
        &.viewed {
            color: map-get($vibrant-colours, 'creative')
        }
    }
}
.productlist {
    //min-height: 500px;
    padding: 1rem;
    @include breakpoint(large) {
        padding: 2rem 0 0 0;
    }
}
.menu-container {
    margin-bottom: 2rem;
    @include breakpoint (medium down) {
        width: 100%;
        padding: 0.5rem 2rem 2rem;
        min-height: 100vh;
        display: none;
        left: 0;
        &.shown {
            display: block;
            position: fixed;
            top: 0;
            bottom: 0;
            z-index: 10;
            overflow-y: scroll;
            padding-top: 0;
            & + .productlist { display: none; }
            background-color: #F2F2F2;
        }


        .button {
            &.close {
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center right;
                background-origin: padding-box;
                padding: 1rem 0.8rem;
                background-image: url('/assets/images/icons/m-close.svg');
                background-size: 14px, 14px, contain;
                position: relative;
                width: 100%;
                right: -2px;
                top: 20px;
            }
        }

    }
    background-color: white;
    @include breakpoint(large) {
        float:left;
        overflow: hidden;
        width: 0;
        &.shown {
            width: 310px;
        }
        transition: width 1s;
        padding: 0;
        height: unset;
        background-color: transparent;

        & > div {
            float: right;
            padding-right: 3rem;
            width: 310px;
        }

    }
    #filtermenu {
        @include breakpoint(medium down) {
            padding-bottom: 4rem;
            padding-top:0;
        }
    }


    .filtermenubuttons {
        padding-top:1rem;
        
        a {
            min-width: 8rem;
            border-radius: 4px;
            &:last-of-type {
                float:right;
            }
        }
        @include breakpoint(medium down) {
            position: fixed;
            bottom: 0;
            left: 0;
            width: calc(100vw);
            padding: 1rem 2rem 0;
            background-color: #F2F2F2;
            z-index: 2;
        }

        @include breakpoint(large) {
            border-bottom: solid 1px #D3D3D4;
        }

        .button {
            &.secondary {
                background-color: #CCD0D2;
            }

        }
    }
}

.sort-container {
    //background-color: #f2f2f2;
    padding-top:0.5rem;
    @include breakpoint(large) {
        padding-top:0;
        background-color: transparent;
    }
    ul {

        justify-content: center;
        @include breakpoint(large) {
            justify-content: flex-end;
            position: absolute;
            right: 0;
            top: -39px;
        }        
        li a {
            font-size: 14px;
            padding: 0.7rem 0.5rem;
        }

    /* Customize the label (the container) */
    .radio-container {
        display: inline-block;
        position: relative;
        padding-left: 15px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
  
  
        /* Hide the browser's default radio button */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
  
 
        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
            border-bottom: solid 1px $body-font-color;
        }
        
        /* When the radio button is checked, add a blue background */
        input:checked ~ .checkmark {
            //background-color:  #5B5B5B;
            font-weight: bold;
            border-bottom: solid 1px $body-font-color;
        }
        
    }


    }
}

#product-container {
    padding-right: 0;
    padding-left: 0;
    @include breakpoint(medium) {
        padding-right: .9375rem;
        padding-left: .9375rem;
    }

    .showmore, .showless {
        padding: 0 0 1rem;
        text-decoration: underline;
        color: #c1c1c1;
        cursor: pointer;
    }
}


#filtermenu {
    /*
    z-index: 5;
    width: 100%;
    background-color: none;
    //height: calc(100% - 106px);
    top: 55px;
    height: calc(100vh - 55px -50px);

    @include breakpoint(medium) {
        position: absolute;
        width: 30%;
        top: 50px;
        background-color: white;
        float: left;
        transition: none;
        min-height: 400px;
        height: unset;
        padding-top: 1rem;
    }

    @include breakpoint(large) {
        width: 20%;
    }    

    .menu-container {
        margin-bottom: 2rem;
    }

    #filtermenu {
        margin: 2rem 3rem 1rem 2rem;
        @include breakpoint(medium) {
            margin: 0 1rem 1rem 0;
        }
    }*/



    li a {
        font-size: 14px;
        padding-left: 0;
    }


     .is-accordion-submenu-parent:not(.has-submenu-toggle)>a:after {
        content: '+' !important;
        position: absolute;
        right: 0;
    }
     .is-accordion-submenu-parent[aria-expanded=true]>a:after {
        content: '-' !important;
        position: absolute;
        right: 0;
    }

     .nested.is-accordion-submenu {
        margin-left: 2rem;
    }

    .checkbox {
        margin-bottom: 0.7rem;
        input[type='checkbox'] { display: none; }
        label {
            position: relative;
            display: table;
            width: 100%;
            line-height: 19px;
            min-height: 19px;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0.6rem;
            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                border: solid 1px #c9c9c9;
                border-radius: 4px;
                content: "\00a0";
                width: 20px;
                height: 20px;
                line-height: 1rem;
                margin-right: 1rem;
                font-size: 1.2rem;
                text-align: center;
                color: white;
                background-color: none;
            }
            span.label-text {
                font-size: 14px;
                color: $body-font-color;
                display: table-cell; padding: 0 1rem 0 2rem;
            }
        }
        input[type="checkbox"]:checked + label {
            &:before {
                @extend %fa-icon;
                @extend .fas;
                content: fa-content($fa-var-check);
                background-color: map-get($vibrant-colours, 'creative');
                border: solid 1px map-get($vibrant-colours, 'creative');
                font-size: 10px;
                padding: 4px;
            }
        }
    }
}



.filterheader {
    /*
    .off-canvas-content .off-canvas.position-left, .position-left {
        &:not(.is-open) {
            transform: translateX(-300px) !important;
        }
    }*/

    position: relative;

    .filterbuttons {
        z-index: 14;
        height: 50px;
        width: 100vw;
        //background-color: #f2f2f2;

        border-top: solid 1px #f2f2f2;
        border-bottom: solid 1px #f2f2f2;

        padding: 0 1rem;
        margin-left: calc((100% - 100vw) / 2);
    
        @include breakpoint(large) {
            width: 100%;
            margin-left: 0;
            position: unset;
            border-top: solid 1px #D3D3D4;
            border-bottom: solid 1px #D3D3D4;
            background-color: white;
            padding: 0;
        }
        .btn {
            margin-bottom: 0;
            letter-spacing: 0.4px;
            font-size: 14px;
            text-align: left;
            display: inline-flex;
            @include flex-align('left', 'middle');
            border-radius: 4px;
            //padding: .85em 0.8rem;
            height: 48px;
            &:after {
                content: url(/assets/images/icons/AF_filter_arrow_icon.svg);
                padding-top:2px;
                margin-left: 25px;
                
            }
            &.open {
                &:after {
                    transform: rotate(90deg);
                }
            }
            &.filterbutton {
                &:before {
                    content: url(/assets/images/icons/AF_filter_icn.svg);
                    width: 35px;
                }

            }
            &.orderbutton {
                float: right;
                &:before {
                    content: url(/assets/images/icons/AF_reorder_icn.svg);
                    float: right;
                    width: 35px;
                }
            }
        }
    }

}

#errorModal {
    text-align: center;
    a {
        text-decoration: underline;
    }
    .button {
        margin: 2rem auto 0;
    }
}
/*
#sampleModal, #carpetModal {
    //position: relative;
    margin-bottom: 1rem;
    border: none !important;
    background-color: transparent;
    text-align: center;
    margin-top: 4rem;
    p {
        color: white;
        //font-size: 26px;
        padding-top:4rem;
    }
    .ok {
        background-image: url('/assets/images/icons/cookie-close.svg');
        background-size: 16px,16px,contain;
        position: absolute;
        height: 16px;
        width: 16px;
        background-repeat: no-repeat;
        padding: 1.5rem;
        display: inline-block;
        background-position: 50%;
        left: 0;
        right: 0;
        margin: auto;        
    }


    a {
        text-decoration: underline;
        color: white;
        padding: 1rem;
        margin-top: 1rem;
        display: block;
    }

    .paint-button {
        
        display: inline-flex;
        align-items: center;
        display: inline-block;

        //height: 3.5em;
        font-weight: 400;
        font-style: normal;
        // The SVG is added as an SVG background image

        background-image: url('/assets/images/buttons/palette/circle/neutral-ethical.svg');

        &.wishlist {
            background-image: url('/assets/images/buttons/palette/circle/neutral-fabulous.svg');
            color: #424242 ;
            p {
                margin-top:4rem;
            }
            a {
                color: #424242 ;
            }

        }

        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
        padding: 2rem;
        height: 275px;
        width: 275px;
        color:white;

    }
}*/