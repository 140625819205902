#floor-gazing {
    
    .intro {
        p {
            margin-bottom: 0;
        }
    }

    .container {
        position: relative;
        margin: 1rem;
        img {
            width: 100%;
        }
    }

    .image-overlay {
        position: absolute;
        bottom: 0;
        top: 0;;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.2); /* Black see-through */
        width: 100%;
        //opacity: 1;
    }


    .heading {
        position: absolute;
        z-index: 1;
        top: 0;
        margin: 0 5px;
        @include breakpoint(xlarge) {
            margin: 1rem;
        }
        text-align: left;
        h2 {
            color: white;
            padding: 1rem;
            font-size: 1.7rem;
            letter-spacing: rem-calc(0.3);
            @include breakpoint(medium) {
                font-size: 1.8rem;
                line-height: 3rem;
            }            
            @include breakpoint(xlarge) {
                font-size: rem-calc(36);
                //line-height: rem-calc(66);
            }
        }
    }
    .paint-button-container {
        display: block;
        text-align: left;
        .paint-button {
            color: #424242;
            padding: 0.8rem 3.5rem;
            opacity: 0.9;
            font-weight: 600;
            font-size: rem-calc(14);
        }
    }

    //room page
    &.room {
        h2 {
            font-size: 1.7rem;

            color: #424242;
            @include breakpoint(medium) {
                line-height: 6.125rem;
                font-size: rem-calc(36);
            }
        }

        .img-container {
            position: relative;
            margin: 2rem 1rem;
            a {
                display: block;
                &:hover {
                    span {
                    text-decoration: underline;
                    }
                }
            }
            span.product {
                position: absolute;
                left: 0.8rem;
                right: 3.6rem;
                bottom: 0.8rem;
                color: white;
                font-size: rem-calc(14);
            }
        }
    }
}
