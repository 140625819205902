.payment-challenge-modal {
	background: rgba(0,0,0,0.7);
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 999;

	iframe {
		border: none;
		background: white;
		width: 100vw;
		height: 100vh;

		@media (min-width: 400px) and (min-height: 410px) {
			width: 390px;
			height: 400px;
			margin: 50vh auto 0 auto;
			transform: translateY(-50%);
		}

		@media (min-width: 510px) and (min-height: 610px) {
			width: 500px;
			height: 600px;
			margin: 50vh auto 0 auto;
			transform: translateY(-50%);
		}

		@media (min-width: 610px) and (min-height: 410px) {
			width: 600px;
			height: 400px;
			margin: 50vh auto 0 auto;
			transform: translateY(-50%);
		}
	}
}

p.checkout-total {
	font-size: 1.4rem;
	color: map-get($vibrant-colours, 'ethically-concious');
	font-weight: 500;

}

