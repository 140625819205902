.cosmos-grid {
    @extend .grid-x;
}
.cosmos-grid-contained {
    @extend .grid-container;
}
.cosmos-cell {
    @extend .cell;
}
div[data-block-type="video"] video {
    width: 100%;
}
.cosmos-panel.cosmos-grid-spacing-margin .cosmos-grid {
    @extend .grid-margin-x;
}
.cosmos-panel:not(.cosmos-grid-spacing-none):not(.cosmos-grid-spacing-margin) .cosmos-grid {
    @extend .grid-padding-x;
}