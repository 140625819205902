

#desktop-search {

    width: 100% !important;

    .search-holder {
        padding-top: 0;
        padding-left: 1rem;
        max-width: 1440px;
        margin: auto;
        position: relative;
        &:before {
            content: url('/assets/images/icons/search.svg');
            display: inline-flex;
            position: absolute;
            top: 14px;
            left: 17px;
        }
    }

    input[type=text] {
        box-shadow: none;
        border: none;
        border-bottom: solid 1px #B7B8C0;
        color: map-get($vibrant-colours, 'experimental');
        margin-bottom: 0;
        background-color: transparent;
        width: 300px;
        letter-spacing: 0.6px;
        display: inline-flex;
        margin-left: 2rem;
        padding-left: 5px;
        font-size: 14px;
        //padding-bottom: 2px;
    }

    p.help {
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 1rem 2rem 2rem 3rem;
        font-size: 10px;
        margin-bottom: 0;
        color: #939891;
        max-width: 1440px;
        margin: auto;
    }

    div.active {
        background-color: map-get($neutral-colours, 'fabulous');
        margin-bottom: 3rem;
        input[type=text] {
            background-color: map-get($neutral-colours, 'fabulous');
        }
        position: absolute;
        width: 100% !important;
        z-index: 5000;
        left: 0;
        padding-left: 1rem;
    }

    .results {
        max-width: 1440px;
        margin: auto;
        padding: 0 2rem 3rem 3rem;
        h2 {
            font-family: $font-family-matter;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 1px;
            color: #0A3039;
            text-transform: uppercase;
            font-weight: normal;
            margin-bottom: 0.5rem;
        }

        p {
            margin: -14px 0;
            font-size: 14px;
        }

        .search-results a {
            font-size: 14px;
            line-height: 14px;
            padding-left: 0;
        }

        .pagination {
            padding: 1rem 0;
            a {
                padding: 14px 20px;
            }
            .active a {
                //background: map-get($vibrant-colours, 'experimental');
                background-color: darken(#dfe9dc, 20%) ;
            }
            .disabled {
                padding: 0;
                a {
                    color: #999;
                    cursor: not-allowed;
                }
            }
            a:hover, a:focus {
                text-decoration: none;
            }
        }
    }
    .button {
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center center;
        background-origin: padding-box;
        padding: 1rem 0.8rem;
        &.close {
            background-image: url('/assets/images/icons/m-close.svg');
            background-size: 14px, 14px, contain;
            position: absolute;
            right: 32px;
            top: 24px;
        }
    }
}

#offCanvasSearch {
    background-color: map-get($neutral-colours, 'fabulous');

    .menu-wrapper {
        background-color: map-get($neutral-colours, 'fabulous');
    }

    #mobile-menu-toplinks {
        background-color: map-get($neutral-colours, 'fabulous');
    }

    input[type=text] {
        box-shadow: none;
        border: none;
        border-bottom: solid 1px #B7B8C0;
        color: #e67455;
        margin-bottom: 0;
        background-color: transparent;
        width: 100%;
        letter-spacing: 0.6px;
        line-height: 50px;
        display: inline-flex;
        margin: 0 1rem;
        padding-left: 5px;
        font-size: 16px;
    }

    .results {
        padding: 0 2rem;
        h2 {
            font-family: $font-family-matter;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 1px;
            color: #0A3039;
            text-transform: uppercase;
            font-weight: normal;
            margin: 1rem 0 0.5rem;
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }

        a {
            font-size: 14px;
            line-height: 20px;
            padding-left: 0;
        }        
    }

    .button.close {
        display: none;
    }
}