@mixin colourme($property, $colour-group, $colour-name) {
    #{$property}: map-get($colour-group, $colour-name) !important;
}

.bg {
&.neutral-ethical {
    background-color: map-get($neutral-colours, 'ethically-concious') !important;
}
&.neutral-creative {
    background-color: map-get($neutral-colours, 'creative')!important;
}    
&.neutral-experimental {
    background-color: map-get($neutral-colours, 'experimental')!important;
}    
&.neutral-lovable {
    background-color: map-get($neutral-colours, 'lovable')!important;
}   
&.neutral-visionary {
    background-color: map-get($neutral-colours, 'visionary')!important;
}   
&.neutral-discerning {
    background-color: map-get($neutral-colours, 'discerning')!important;
} 
&.neutral-fabulous {
    background-color: map-get($neutral-colours, 'fabulous')!important;
}      

&.earth-ethical {
    background-color: map-get($earth-colours, 'ethically-concious')!important;
    color: white;
}
&.earth-creative {
    background-color: map-get($earth-colours, 'creative')!important;
}    
&.earth-experimental {
    background-color: map-get($earth-colours, 'experimental')!important;
}    
&.earth-lovable {
    background-color: map-get($earth-colours, 'lovable')!important;
}   
&.earth-visionary {
    background-color: map-get($earth-colours, 'visionary')!important;
}   
&.earth-discerning {
    background-color: map-get($earth-colours, 'discerning')!important;
} 
&.earth-fabulous {
    background-color: map-get($earth-colours, 'fabulous')!important;
} 

&.vibrant-ethical {
    background-color: map-get($vibrant-colours, 'ethically-concious')!important;
}
&.vibrant-creative {
    background-color: map-get($vibrant-colours, 'creative')!important;
}    
&.vibrant-experimental {
    background-color: map-get($vibrant-colours, 'experimental')!important;
}    
&.vibrant-lovable {
    background-color: map-get($vibrant-colours, 'lovable')!important;
}   
&.vibrant-visionary {
    background-color: map-get($vibrant-colours, 'visionary')!important;
}   
&.vibrant-discerning {
    background-color: map-get($vibrant-colours, 'discerning')!important;
} 
&.vibrant-fabulous {
    background-color: map-get($vibrant-colours, 'fabulous')!important;
} 
&.light-grey {
    background-color: #F7F7F7;
} 
&.white {
    background-color: white;
}
}