#retailer-search {

    .cosmos-grid {
        margin: 0 auto 1rem !important;
    }

    .cosmos-form {
        position: relative;
    }

    select.greenarrow {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28169, 205, 52%29'></polygon></svg>") !important;
    }

    padding-bottom: 4rem;

    input[type=text] {
        margin-bottom: 7px;
        height: 48px;
        border-radius: 4px;
        box-shadow: none;

    }   
    .button {

        margin-bottom: 7px;
        letter-spacing: 0.4px;
        font-size: 14px;
        text-align: left;
        display: flex;
        @include flex-align('center', 'middle');
        border-radius: 4px;
        height: 48px;

        &.find {
            &:before {
                content: url(/assets/images/icons/current_location_icon_white.svg);
                //text-align: left;
                width: 0;
                display: inline-block;
            }
            span {
                //@include flex-align('center', 'middle');
                width: 100%;
                text-align:center;
                display: block;
            }
        }
        &.close {
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            background-origin: padding-box;
            padding: 1rem 0.8rem;
            &.close {
                background-image: url('/assets/images/icons/m-close.svg');
                background-size: 14px, 14px, contain;
                position: absolute;
                right: 1.5rem;
                top: 1rem;
            }
        }
    }

    .map-key {
        @include breakpoint(medium) {
            margin-right: 1rem;
        }
        
        line-height: 48px;
        li {
            color: white;
            font-size: 14px;
            @include breakpoint(medium) {
                padding: 0 1rem;
            }
            
            img {
                padding-right: 3px;
            }
        }
    }

    .map-container {
        @include breakpoint(medium) {
            height: 500px;
        }
    }

    #map {
        height: 450px;
        @include breakpoint(medium) {
            height: 500px;
        }
    }

    #results {
        background-color: white;
        position: relative;
        .info {
            background-color: white;
            position: absolute;
            padding: 0.5rem 1rem;
            border-bottom: solid 1px #CDCDCD;
            @include breakpoint(large) {
                margin: 0 1rem 0 1rem;
                padding: 0.5rem 0;
            }
            
        }
        .list-container {
            overflow-y: scroll;
            overflow-x: hidden;
        }
        .list {
            margin: 4.5rem 1rem 1rem 1rem;
            @include breakpoint(medium) {
                height: calc(500px - 120px);
            }
            @include breakpoint(large) {
                margin-top: 5rem;
                height: calc(500px - 100px);
            }
        }

    }
            .entry {
                padding-top: 1rem;
                border-bottom: solid 1px #CDCDCD;
                h3 {
                    font-family: $font-family-matter;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #424242;
                    @include breakpoint(large) {
                        font-size: 18px;
                    }
                    margin-bottom: 1rem;
                    margin-top: 0;
                }
                p {
                    &.addr {
                        margin-bottom: 0;
                    }
                }
                .button {
                    margin-bottom: 7px;
                    letter-spacing: 0.4px;
                    font-size: 14px;
                    text-align: left;
                    display: flex;
                    @include flex-align('center', 'middle');
                    border-radius: 4px;
                    margin: 1rem 0;
                }
                figcaption {
                    text-transform: uppercase;
                    font-size: 10px;
                    letter-spacing: 0.5px;
                    font-weight: bold;
                    padding-top:7px;
                    p:not(.distance) {
                        padding: 0 2rem;   
                    }
                    .distance {
                        text-transform: none;
                        display: flex;
                        font-size: 14px;
                        color: #424242;
                        font-weight: normal;
                        @include flex-align('center', 'middle');
                        &:before {
                            content: url('/assets/images/icons/map_pointer_icon_grey.svg');
                            padding-right:10px;
                        }
                    }
                }
                &.af_retailer {
                    .button {
                        background-color: map-get($vibrant-colours, 'creative');
                    }
                    figcaption {
                        color: map-get($vibrant-colours, 'creative');
                    }
                }
            

        }


    .form-container {

        @include breakpoint(xlarge) {
            padding: 3rem 6rem;
        }
        .entry {
            border-bottom: none;
            padding-top: 0;
        }
    }

    .padded-block {
        //margin-bottom: 3rem;
        @include breakpoint(xlarge) {
            margin-right: 9rem;
            margin-left: 9rem;
        }

    }

    .searchbar {
        background-color:  map-get($earth-colours, 'visionary');
        padding: 1rem 0.5rem 0.5rem 1rem;
    }

    .searchblock {
        background-color: white;
        padding: 1rem;
        margin: 0 auto 2rem;
        border-radius: 4px;

        p {
            font-size: 18px;
            font-weight: 500;
        }



        @include breakpoint(medium) {
            padding: 2rem 1.5rem;
        }
    }

    .maplink {
        margin-bottom: 7px;
        letter-spacing: 0.4px;
        font-size: 14px;
        text-align: left;
        display: inline-flex;
        @include flex-align('left', 'middle');
        border-radius: 4px;
        padding: .7em 1.5rem 0.7rem 0.8rem;
        &:before {
            content: url(/assets/images/icons/AF_Find_Retailer_icn.svg);
            width: 35px;
            display: inline-block;              
        }  
    }


    ol { 
        font-size: 16px;
        margin-left: 0;
        counter-reset: item; 
        padding-left: 10px; 
        li { 
            display: block;
            
            &:before { 
                content: counters(item, ".") " "; 
                counter-increment: item;
                font-weight: 500;
            }
            li li {
                &:before {
                    content: counter(item, lower-alpha) ". ";
                }
            }
        }
    }
    hr {
        margin-top: 1rem;
        margin-bottom: 3rem;
        border-width: 1px;
        border-color: #D3D3D4;
    }
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
}

::-webkit-scrollbar-track {
    background-color: #ccc;
    border-radius: 12px;
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #777;
}