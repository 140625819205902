#sitemap {
    margin-bottom: 6rem;
    #main {
        margin-top: 3rem;
    }

    ul {

        li {
            a {
                border-bottom: solid 1px #eee;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}