#blog-scroller {
    max-width: $global-width;

    .slick-slide {
        padding: 0 0.3rem;
        @include breakpoint(large) {
            padding: 0 1rem;
        }
        .post-title {
            h3 {

                font-family: $font-family-recife-display;
                color: white;
                font-size: 14px;
                letter-spacing: 0.17px;
                line-height: 18px;

                @include breakpoint(large) {
                font-size: 20px;
                letter-spacing: 0.17px;
                line-height: 26px;
                }
            }
        }
        .post {
            &.cover-image {
                @include breakpoint(medium) {
                padding-bottom: 100%;
                }
            }
        }
        .thb-post-top {
            display: none;
        }
        .post-content {
            p {
                margin-bottom: 0;
            }
        }
        
        .featured-title {
            min-height: 0;
            padding: 0.2rem 0.4rem;
            background: map-get($vibrant-colours, 'ethically-concious');
            //min-height: 0;
            //background: rgba(0,0,0,0.4);
            display: block;
            position: absolute;
            bottom: 0;
            @include breakpoint(medium) {
                background: map-get($vibrant-colours, 'ethically-concious');
                //min-height: 120px;
               }
            
        }
    }

    .slick-prev {
        visibility: hidden;
        left: 0;
        &:before {
            content: url('/assets/images/icons/arrow_left_dk.svg');
            visibility: visible;
            position: absolute;
            padding: 0.5rem 0.8rem 0.4rem;
            background-color: white;
            opacity: 1;
            top: 62%;
            left: 0;
            @include breakpoint(large) {
                left: 2rem;
            }
        }
    }
    .slick-next {
        visibility: hidden;
        right:0;
        &:before {
            content: url('/assets/images/icons/arrow_right_dk.svg');
            visibility: visible;
            position: absolute;
            padding: 0.5rem 0.8rem 0.4rem;
            background-color: white;
            opacity: 1;
            top: 62%;
            right: 0;
            @include breakpoint(large) {
                right: 2rem;
            }

        }
    }
}
#blog-category-select {
    text-align: right;
    h2 {
        display: none;
    }
    select {
        margin: 1rem 0;
        width: unset;
    }
}
#blog-postlist {
    .wpb_column {
        padding: 0;
    }
    article {
        max-height: unset;
    }



    .post .thb-post-top {
        text-align: left;
        display: block;
        .post-date {
            color: #0A3039;
            font-size: 1rem;
            line-height: 21px;
            &:before {
                content: '\2014';
                padding-right: 0.5rem;
            }
        }
    }
    .blogpost {
        .post-gallery {
            padding: 2rem 0;
            background: transparent;
            img {
                max-height: unset;
            }
        }
        h2 {
            font-size: 26px;
            line-height: 36px;
            font-weight: 600;
            @include breakpoint(large) {
                font-size: 36px;
                line-height: 46px;
            }
            a {
                color: white;
                letter-spacing: 0.3px;
            }
            
        }
        .content-side {
            padding: 1rem;
            text-align: right;
            @include breakpoint(large) {
                padding-right: 1rem;
                padding-left: 3rem;
            }

            font-size: 1rem;
            .post-title {
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                @include breakpoint(large) {
                    margin-bottom: 2rem;
                    margin-top: 2rem;
                }
            }
            .thb-post-top {
                text-align: right !important;
                &.post-date {
                    display: block !important;
                }
            }
            &.medium-order-1 {
                text-align: left;
                @include breakpoint(large) {
                padding-right: 3rem;
                padding-left: 1rem;
                }
                .thb-post-top {
                    text-align: left !important;
                }
            }

        }
        footer {
            background-color: transparent;
        }
    }
    .nav-links {
        text-align: center;
        margin: 2rem 0 2.5rem;
        .page-numbers {
            line-height: 45px;
            width: 45px;
            height: 45px;
            font-size: 14px;
            color: #424242;
            padding: 0;
            a {
                padding: 0;
                &:hover {
                    background: white;

                }
            }
            &.current {
                padding: unset;
                background: #eee;
            }
        }
    }
}

//wp overwrites th is!!!
@include breakpoint(large) {
.show-for-large {
    display: block !important; 
}
}

.post.cover-image .thb-placeholder .wp-post-image {
    object-fit: contain;
}

#blogpost {
    .post-share-container {
        .share-article {
            position: absolute;
            left:-100px;
            top:0;
            a {
                i {
                    display: none;
                }
            }            
        }
        .footer-share {
            margin: 3rem 0 4rem;
            .share-article {
                text-align: center;
                width: 100%;
                max-width: unset;
                position: inherit;
                justify-content: center;
                display: inline-flex;
                margin-bottom: 0;
                a {
                    margin: 1rem 0.5rem;
                    float:left;
                    i {
                        display: none;
                    }
                }
            }
        }


        a.facebook {
            visibility: hidden;
            &:before {
                content: url("/assets/images/icons/AF_facebook-icn-orange.svg");
                visibility: visible;
            }
        }
        a.twitter {
            visibility: hidden;
            &:before {
                content: url("/assets/images/icons/AF_twitter-icn-orange.svg");
                visibility: visible;
            }
        }    
        a.pinterest {
            visibility: hidden;
            &:before {
                content: url("/assets/images/icons/AF_pinterest-icn-orange.svg");
                visibility: visible;
            }
        }      
        a.email {
            visibility: hidden;
            &:before {
                content: url("/assets/images/icons/AF_Email-icn-orange.svg");
                visibility: visible;
            }
        }          
        a.comment {
            display: none !important;
        }
    }
    .post-content-container {
        padding-left:0;
    }
    &.post-detail-row {
        padding: 0;
    }

    .post-author {
        font-size: rem-calc(14);
        a {
        font-size: rem-calc(14);
        color: #424242;
        }
    }
    h1 {
        font-family: $font-family-recife-display;
        size: rem-calc(48);
        line-height: rem-calc(68);
        color: #424242;
        font-weight: bold;
        margin-bottom: 2rem;
    }
    .thb-post-top {
        text-align: left;
        justify-content: unset;
        margin-bottom: 1rem;
        .post-date {
            &:before {
                content: '\2014';
                padding-right: 0.5rem;
            }
            font-size: rem-calc(14);
            color: #424242;
            line-height: 22px;
        }

    }
    .post-content {
        .wp-caption-text {
            font-family: $font-family-matter;

        }
        h2,h3,h4 {
            font-family: $font-family-recife-display;
            font-size: rem-calc(28);
            line-height: rem-calc(32);
            color: #0A3039;
            font-weight: 600;
            margin-bottom: 2rem;
        }
        h2, h3 {
            margin-top: 3rem;
        }
        a {
            color: map-get($vibrant-colours, 'experimental') !important;
            text-decoration: underline;
        }
        .pullquote {
            color: map-get($earth-colours, 'discerning');
            font-family: $font-family-recife-display;
            font-style: italic;
            font-size: rem-calc(28);
            line-height: rem-calc(38);
        }
        p:empty {
            display: none;
          }
    }
    .article-navigation {
        border-left: none;
        border-right: none;
        border-bottom: none;
        a {
            padding: 0;
            span {
                font-size: rem-calc(14);
                line-height: rem-calc(24);   
                color: #424242;
            }
            svg {
                display: none;

            }
            h6 {
                font-family: $font-family-recife-display;
                font-size: rem-calc(20);
                @include breakpoint(medium) {
                    font-size: rem-calc(28);
                }
                
                line-height: rem-calc(32);
                color: #548A7F;
                font-weight: 600;
                margin-bottom: 1rem;      
                max-width: 300px; 
                min-height: 130px;         
            }
            span.date {
                font-weight: normal;
                color: #424242;
            }
        }
        .post-nav-link {
            &.next {
                float: right;
            }
        }
    }
}
#archive-title {
    background: none;
    h1, h1 span {
    font-family: $font-family-recife-display;
    font-size: rem-calc(28);
    line-height: rem-calc(32);
    color: #0A3039;
    font-weight: 600;
    margin-bottom: 2rem;
    text-align: left;
    }
}
.thb-cookie-bar {
    display: none !important;
}
 
.post-style2-links {
    .addthis_toolbox {
        display: inline-block;
    }
    .share-link {
        //background-image: url('/assets/images/buttons/palette/circle/paint-circle-earth-fabulous.svg');
        svg {
            display: none;
        }
        display: inline-block !important;
        .share_icn {
            background-image: url('/assets/images/icons/sml_splat_2_F2F2F2.svg');
            background-size: 100%;
            width: 60px;
            height: 60px;
            display: inline-flex;
            line-height: 60px;
            font-family: $body-font-family;
            font-weight: 500;
            margin: 0 4rem 0 1rem;
            position: relative;
           /*
            img {
                padding: 32% 27%;
                margin-right: 15px;
            }*/
            &:before {
                background-size: 65%;
                top: 19px;
                left: 14px;
            }
            span.title {
                margin-left:4rem;
            }
        }
        font-size: 0.9rem;
    }
    .comment-link {
        margin-right: 50px;
        display: inline-block !important;
        .comment_icn {
            background-image: url('/assets/images/icons/sml_splat_1_F2F2F2.svg');
            background-size: 100%;
            width: 60px;
            height: 60px;
            display: inline-flex;
            line-height: 60px;
            font-family: $body-font-family;
            font-weight: 500;
            font-size: 0.9rem;
            img {
                padding: 32% 27%;
                margin-right: 2px;
            }
        }
        font-size: 0.9rem;
    }
    .wp-share-ct {
        display: inline-block !important;
        font-size: 0.9rem;
        font-weight: normal;
    }
}