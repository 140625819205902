#cnc {
    h2.title {
        font-size: rem-calc(28px);
        letter-spacing: rem-calc(0.23);
        margin-top:1rem;
    }
    .runner {
        .bg {
            padding: 1rem;
            margin:1rem;
            @include breakpoint(large) {
                padding:2rem; 
            }
        }
        h3 {
            margin: 1rem 0;
            font-size: rem-calc(18px);
            color: #424242;
            @include breakpoint(large) {
                font-size: rem-calc(28px);
            }
            letter-spacing: rem-calc(0.23);
        }
        .paint-button-container {
            margin-bottom: 0;
        }
        .paint-button {
            padding: 0.8rem 4rem;
        }
        p {
            font-size: rem-calc(16);
        }
    }
    #productdetail #pricing-grid form {
        margin-left: 0;
        margin-bottom: 1.5rem;
        position: inline;

        label {
            line-height: 5px;
            margin-bottom: 0;
            height: 30px;
            width: 90%;
            float:left;
            &:before {
                position: relative;
                line-height: 0.8rem;
                margin-right: 0.5rem;
            }

            .label-text {
                width: 100%;
                padding-left:0;
            }
        }

        button {
            margin: unset;
            left: unset;
            right: 0;
            @include breakpoint(medium) {
                right: 2rem;
            }
            @include breakpoint(large) {
                right: 4rem;
            }   
            bottom: 0;
        }


    }
}

#runner-buttons {

    .cell {
            &:nth-child(odd) {
                padding-right: 3px !important;
            }
            &:nth-child(even) {
                padding-left: 3px !important;
            }

    }
}


