.account {

    

    p.error {
        color: map-get($vibrant-colours, 'experimental');
        a {
            color: map-get($vibrant-colours, 'experimental');
            text-decoration: underline;
            font-weight: 500;
        }
    }

    .button {
        background-color: #5B5B5B;
        //font-weight: 500;
        letter-spacing: 0.5px;
        padding: 1rem 3rem 1.1rem;
        text-transform: none;
        margin-bottom: 0;
        &.form-post-button {
            background-color: map-get($vibrant-colours, 'ethically-concious');
        }
    }
    form {
        input[type=text] {
            margin-bottom: 7px;
            height: 48px;
            border-radius: 4px;
            box-shadow: none;
        }
    }

    .rendili-tabs {
        margin-top: 2rem;
        margin-left: 0;
        border-top: solid 1px #D3D3D4;
        border-bottom: solid 1px #D3D3D4;
        li {
            display: inline-block;
            padding: 0.5rem 0.2rem;
            @include breakpoint(large) {
                padding: 0.5rem 1rem;
            }
            cursor: pointer;
            &:after {
                content: '|';
                color: #D3D3D4;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                @include breakpoint(large) {
                    padding-left:1.5rem;
                    padding-right:0;
                }
            }
            &:last-of-type {
                &:after {
                    content: '';
                }
            }
            &.active {
                color: map-get($vibrant-colours, 'ethically-concious');
            }
        }
    }

    .rendili-tab {
        text-align: left;
        h2, h3, h4 {
            color: $body-font-color;
            font-family: $font-family-matter;
            font-weight: 500;
            font-size: rem-calc(24);
            border-bottom: solid 1px #D3D3D4; 
            margin-bottom: 2.5rem;
            line-height: 60px;
        }
        h4 {
            font-size: 1rem;
            line-height: 2rem;
            margin-bottom: 0.2rem;
        }
        .content {
            @include breakpoint(medium) {
                padding: 0 2rem;
            }
            @include breakpoint(large) {
                padding: 0 5rem;
            }
            a {
                text-decoration: underline;
            }
            a.button {
                text-decoration: none;
            }
            p.tick {
                margin-bottom: 0.2rem;
                &:before {
                    content: url(/assets/images/icons/tick-bullet.svg);
                    padding-right: 0.4rem;
                }
            }
        }
    }
    .standout {
        color: map-get($vibrant-colours, 'creative');
    }

	table {
		background: transparent;
        margin-bottom: 4rem;
		thead {
			border: none;
			border-bottom: solid 1px #D3D3D4;
			background: transparent;
			th {
				font-weight: 500;
				color: $input-placeholder-color;
			}
		}
		tbody {
			background-color: transparent;
			border: none;
			tr {
                border-bottom: solid 1px #D3D3D4;
                td {
                    &.standout {
                        color: map-get($vibrant-colours, 'creative');
                    }
                }
                &.open {
                    td {
                        font-weight: 500;
                    }
                    .button {
                        background-color: map-get($vibrant-colours, 'ethically-concious');
                    }
                }
			}
		}
		tfoot {
			border: none;
			border-top: solid 1px #D3D3D4;
			background: transparent;			
			td {
				font-weight: 500;
			}
		}
	}
}
