.iconcircle {
    >div {
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    background-size: cover;
    width: 100%;
    margin: auto;
    }
    img {
        position: absolute;
        left:0;
        right:0;
        bottom:0;
        top:0;
        margin:auto;
    }
    p {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }
     
}