#mini-basket {
    position: relative;
    z-index:2;
}

#main-basket, #wishlist, #basket, .basket {

    
    @include breakpoint(large) {
        margin-bottom: 4rem;
    }


    .headers {
        border-bottom: solid 1px #D3D3D4;
        padding: 0.5rem 0 1rem;
        margin-bottom: 1rem;
        color: #A3A3A3;
    }
    p.extra {
        &:before {
            content: url('/assets/images/icons/tick-bullet.svg');
            padding-right: 0.4rem;
        }
    }

    p.tree {
        &:before {
            @extend %fa-icon;
            @extend .fas;
            content: fa-content($fa-var-tree);
            color: map-get($earth-colours, 'ethically-concious');
            padding: 0 1rem 0 0;
            font-size: 1rem;
        }
        a {
            color: map-get($earth-colours, 'ethically-concious') !important;
        }
    }
    form {
        p.price, p.qty {
            line-height: 44px;
        }

        button.remove {
            padding: 10px;
            border: none;
            cursor: pointer;
        }
        label {
            display: inline-flex;
            margin-bottom: 0;
            color: #cacaca;
            font-family: $font-family-matter;
            text-transform: none;
        }

        select, input[type=number] {
            height: 40px;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 0.5rem;
            margin-right:0.5rem;
        }
        /*
        input[type=number] {
            margin-left:5px;
            @include breakpoint(medium) {
                margin-left: unset;
            }
        }*/
        .input-group {
            margin-bottom: 0;
            padding-right: 5px;
            .button {
                font-size: 1rem;
                padding: 0 1rem;
                background-color: #CCD0D2;
                padding-left: 7px;
                padding-right: 7px;
                &.minus {
                    border-radius: 4px 0 0 4px;
                    padding-left: 8px;
                    padding-right: 8px;
                }                
                &.plus {
                    border-radius: 0 4px 4px 0;
                }
                border: none;
                cursor: pointer;
            }
            input[type=number] {
                margin: 0;
                border-radius: 0;
                color: black !important;
                background-color: white !important;
                &:disabled {
                    color: black !important;
                }
            }
            
        }
    }

    .footer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: solid 1px #D3D3D4;
        border-bottom: solid 1px #D3D3D4;
        margin-bottom: 4rem;
        .button {
            border: none;
            cursor: pointer;
            margin-top: 0.5rem;
            margin-bottom: 0;
            &.view {
                background-color: #CCD0D2;
            }
            width: 100%;
            @include breakpoint(medium) {
                width: unset;
            }
        }
        .total {
            font-size: 16px;
            font-weight: 500;
        }
    }
    .item {
        border-bottom: solid 1px #D3D3D4;
        padding: 0.5rem 0;
        margin-bottom: 0;
        font-size: 14px;


        @include breakpoint(large) {
            font-size: 16px;
        }
        
        .detail {

                padding: 0 0.4rem 0 0;

        }

        p {
            font-size: 14px;
            @include breakpoint(large) {
                font-size: 16px;
            }
            margin-bottom: 0.4rem;
        }

        a{
            color: map-get($vibrant-colours, 'creative');
            text-decoration: underline;
            display: block;
            @include breakpoint(medium) {
                margin-top:2rem;
            }
        }

        img {
            display: block;
            &.thumb {
                @include breakpoint(medium) {
                    width: 130px;
                }
                @include breakpoint(large) {
                    width: 160px;
                }            
            }
            padding-right: 0.3rem;
            padding-bottom: 0.5rem;

        }
        &:last-of-type {
            border-bottom: none;
        }
    }
}

#basket, .basket {
    background-color: $white;
    border-radius: 4px;
    margin-bottom: 2rem;
    .headers {
        padding-top:1rem;
    }
    .footer {
        margin-bottom: 1rem;
        .button {
            border: none;
            cursor: pointer;
            margin-top: 0;
        }
    }
}



#mini-basket-holder {
    position: absolute;
    background-color: white;
    
    right: -11px;
    border: solid 1px #eee;
    width: 80vw;
    height: 100vh;

    z-index: 1;
    padding: 0 1rem 1rem;
    max-height: calc(100vh - 56px);
    overflow-y: auto;


    @include breakpoint(large) {
        position: absolute;
        top: 45px;
        width: 525px;
        max-height: unset;
        height: unset;
        overflow-y: hidden;
        .item-container {
            max-height: 450px;
            overflow-y: scroll;
        }
    }

    .footer {
        padding-top: 1rem;
        padding-bottom: 4rem;
        border-top: solid 1px #D3D3D4;
        .button {
            border: none;
            cursor: pointer;
            text-transform: none;
            margin-bottom: 0.5rem;
            font-weight: normal;
            &.view {
                background-color: #CCD0D2;
            }
        }
        .total {
            font-size: 16px;
            font-weight: 500;
        }
        @include breakpoint(large) {
            padding-bottom: 1rem;
        }
    }

    a.close {
        position: relative;
        top: 0;
        right: 0;
        float: right;
        z-index: 1;
        padding: 0.6rem 0 0.5rem;
        cursor: pointer;
        .lazyloading {
        opacity: 1;
        filter: none;
        }
    }

    h3 {
        @include breakpoint(small only) {
            font-size: 14px;
        }

        font-size: 18px;
        position: relative;
        font-family: $font-family-recife-display;
        border-bottom: solid 1px #D3D3D4;
        padding: 1rem 0 0.5rem 0;
        background-color: white;
    }

    form {
        p.price {
            line-height: 44px;
        }
        p.extra {
            &:before {
                content: url('/assets/images/icons/tick-bullet.svg');
                padding-right: 0.4rem;
            }
        }
        button.remove {
            border: none;
            cursor: pointer;
            padding: 10px;
            cursor: pointer;
        }
        label {
            display: inline-flex;
            margin-bottom: 0;
            color: #cacaca;
            font-family: $font-family-matter;
            text-transform: none;
        }
        select, input[type=number] {
            height: 40px;
            display: inline-block;
            font-size: 14px;
            margin-bottom: 0.5rem;
            margin-right:0.5rem;
            padding: 0.5rem;
        }
        .input-group {
            margin-bottom: 0;
            padding-right: 0.5rem;
            .button {
                font-size: 1rem;
                padding: 0 1rem;
                background-color: #CCD0D2;
                &.minus {
                    border-radius: 4px 0 0 4px;
                }                
                &.plus {
                    border-radius: 0 4px 4px 0;
                }
                border: none;
                cursor: pointer;
            }
            input[type=number] {
                margin: 0;
                border-radius: 0;
                color: black !important;
                background-color: white !important;
                font-size: 14px;
                &:disabled {
                    color: black !important;
                }
            }
        }
    }

    .item {
        border-bottom: solid 1px #D3D3D4;
        padding: 0.5rem 0;
        margin-bottom: 0;
        font-size: 14px;

        .detail {
            @include breakpoint(medium) {
                padding: 0 0.7rem;
            }
        }

        p {
            margin-bottom: 0.4rem;
            font-size: 14px;
        }



        img {
            display: block;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
}

//basket main page
.basket-links {
    .cosmos-cell >div {
        padding: 1rem 1.5rem;
    }
    a {
        text-decoration: underline;
    }
    p {
        margin: 0 auto 1rem;
        font-size: 14px;
        line-height: 20px;
        img {
            height: 100%;
        }
        background-repeat: no-repeat;
        &.cnc {
            background-image: url('/assets/images/basket/painted_circle_B3C0B5.svg');
            height:106px;
            width: 106px;
            background-size: 100%;
        }
        &.samples {
            background-image: url('/assets/images/basket/painted_circle_88898A.svg');
            height:106px;
            width: 106px;
            background-size: 100%;
        }      
        &.kit {
            background-image: url('/assets/images/basket/painted_circle_BBA691.svg');
            height:106px;
            width: 106px;
            background-size: 100%;
        }          
        &.freesamples {
            background-image: url('/assets/images/basket/painted_circle_D3D3D4.svg');
            height:106px;
            width: 106px;
            background-size: 100%;
        }          
    }
}