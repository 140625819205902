.starholder {
    background-color: transparent;
    margin-top: 2rem;
}

.slick-slide img {
    margin: auto;
}

.starlist {
    padding: 3rem 1rem;
    h2 {
        color: white;
        font-family: $font-family-rollerscript-smooth;
        font-weight: normal;
        font-size: 2.5rem;

        @include breakpoint(medium) {
            font-size: rem-calc(54);

        }
        
        &:after {
            z-index: 0 !important;
            bottom: -5px !important;
        }
        margin-bottom: 3rem;
    }
    #starscroller {
            //display: flex;
        
        .slick-track {
            
            align-items: center;
            margin-left: auto;
            margin-right: auto;
        }
        .slick-prev {
            left: -84px;
            z-index: 50;
            height: 111px;
            width: 111px;
            background-image: url('/assets/images/buttons/Trends_circle_left.svg');
            background-size: 111px;
            background-repeat: no-repeat;
            &:before {
                content: url('/assets/images/icons/arrow_left_dk.svg');
                position: absolute;
                right: 35px;
                top: 46px;

            }
        }
        .slick-next {
            right: -84px;
            z-index: 50;
            height: 111px;
            width: 111px;
            background-image: url('/assets/images/buttons/Trends_circle_left.svg');
            background-size: 111px;
            &:before {
                content: url('/assets/images/icons/arrow_right_dk.svg');
                position: absolute;
                left: 35px;
                top: 46px;
            }
        }

        .starscroller-item {
            text-align: center;
            padding: 0 1rem;
            margin-bottom: 3rem;
            a, a:hover, a:visited, a:active, a:focus {
                border: none;
                text-decoration: none;
                box-shadow: none;
                outline: none;
            }
            h3 {
                color: white;
                font-size: rem-calc(21);
                margin: 1rem 0;
            }
            img {
                //width: 100%;
                margin: auto;
                &.circled {
                    height: 122px;
                    width: 122px;
                    border-radius: 50%;
                    bottom: 0;
                    margin-bottom: 1rem;
        
                }
            }
        }
    }
}


.star {
    .cosmos-panel {
        padding: 0;
    }
    .cosmos-grid {
        .cosmos-cell {
            padding: 0;
        }
    }

    .dark {
        color: $body-font-color;
    }
    .light {
        color: white;
        a {
            color: white;
        }
    }

    .inner {
        color: white;
        padding: 0.5rem;
        @include breakpoint(large) {
            //margin: 1rem 0;
            //padding: 1rem;
        }
        &.detail {
            background-color: white;
            color: $body-font-color !important;
            padding: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            h2, p {
                color: $body-font-color !important;
            }
            .designer-header {
                padding-bottom: 2rem;
            }

        }
    }

    #product-buttons {
        margin-top: 1rem;
        margin-bottom: 1rem;
        @include breakpoint(large only) {
            margin-top: 0;;
        }
    }

    .footer {
        margin-top: 1rem;
    }

    padding: 1rem;
    .align-right {
        //h2, .cosmos-panel {
            text-align: right;
        //}
    }
    
    a.paint-button {
        font-size: 14px;
        padding: 0.8rem 1.5rem;
        @include breakpoint(large) {
            margin-right: 1.5rem;
            margin-left: 0;
        }
    }


    &.current {
        img {
            filter: grayscale(100%);
        }               
    }
    .img-container {
        height:100%;
        overflow: hidden;
        @include breakpoint(large) {
            max-height: unset;
        }
        img {
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%, -50%);
            position: relative;
            left: 50%;
            top: 50%;
            max-width: unset;
        }
    }

    .align-right {
        text-align: left;
        img {
            float:left;
        }
        @include breakpoint(large) {
            text-align: right;
            img.circled {
                float:right;
            }
            a.paint-button {
                margin-right: 0;
                margin-left: 1.5rem;
            }
        }
    }

    img {

        &.circled {
            height: 140px;
            border-radius: 50%;
            bottom: 0;
            margin-bottom: 2rem;
            width: 140px;
        }
    }

    .thumbs {
        .img-container {
            overflow: hidden;
            height: 35px;
        }

        img {
            max-width: 150px;
            @include breakpoint(large) {
                max-width: 120px;
            }
        }

    }
    h2 {
        font-size: 24px;
        letter-spacing: 0.2px;
        line-height: 34px;
        color: white;
        font-weight: bold;
        font-family: $font-family-recife-display;
        @include breakpoint(medium) {
            font-size: 36px;
            letter-spacing: 0.3px;
            line-height: 50px;
        }
        &.dark {
            color: $body-font-color;
        }
    }

    h3 {
        font-family: $font-family-matter;
        color: $body-font-color;
        font-size: rem-calc(20);
        font-weight: 500;
        padding-bottom: 1rem;
    }

#lifestyle-scroller {
    .slick-slide {
        padding: 0 0.5rem;
    }
    .slick-prev {
        left: -16px;
        &:before {
            content: url('/assets/images/icons/grey-slick-left.svg');
            height: 26px;
            width: 14px;
        }
    }
    .slick-next {
        right: -16px;
        &:before {
            content: url('/assets/images/icons/grey-slick-right.svg');
            height: 26px;
            width: 14px;
        }
    }
}
    
}
