$site-font-path: "../fonts" !default;
$font-family-rollerscript-smooth: "Rollerscript Smooth", sans-serif;
$font-family-matter: Matter, helvetica, arial, sans-serif;
$font-family-recife-display: 'Recife Display', Georgia, "Times New Roman", Times, serif;

@font-face {
    font-family: 'Rollerscript Smooth';
    src: url('#{$site-font-path}/rollerscript-smooth-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/rollerscript-smooth-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-bold-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-bolditalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-light-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-lightitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-lightitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-medium-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-regular-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('#{$site-font-path}/matter-regularitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/matter-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-black-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-blackitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-blackitalic-webfont.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-bold-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-bolditalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-book-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-book-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-bookitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-bookitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-light-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-lightitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-lightitalic-webfont.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-medium-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-regular-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-regularitalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-semibold-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Recife Display';
    src: url('#{$site-font-path}/recifedisplay-semibolditalic-webfont.woff2') format('woff2'),
         url('#{$site-font-path}/recifedisplay-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}