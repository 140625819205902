.mega-accordion {



    h2  {


    

        position: relative;
        cursor: pointer;
        font-family: $font-family-matter;
        color: black;
        letter-spacing: normal;
        font-weight: 400;
        line-height: 2.5rem;
        font-size: 1.4rem;
        padding-right: 2rem;
        @include breakpoint(large) {
            font-size: rem-calc(32px);
            line-height: rem-calc(57px);
        }
        &:before {
            content: url('/assets/images/icons/concertina_arrow_close.svg');
            position: absolute;
            top: 2px;
            right: 1rem;
            //margin-top: -0.75rem;
            
        }
        & + div {
            -webkit-transform: scaleY(1);
            -o-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
            position: relative;
            opacity: 1;
        }
        &.closed {
            & + div {
                -webkit-transform: scaleY(0);
                -o-transform: scaleY(0);
                -ms-transform: scaleY(0);
                transform: scaleY(0);
                max-width: 70vw;
                position:absolute;
                opacity: 0;
            }
            &:before {
                content: url('/assets/images/icons/concertina_arrow_open.svg');
                //margin-top: -.5rem;
            }
        }
    }

    .section {
        .subsection:last-child {
            margin-bottom: 2rem;
        }

            display: block;
            scroll-margin-top: rem-calc(60px);
            @include breakpoint(large) {
                scroll-margin-top: rem-calc(150px);
            }
        
        .subsection {

                display: block;
                scroll-margin-top: rem-calc(60px);
                @include breakpoint(large) {
                    scroll-margin-top: rem-calc(150px);
                }
            
        }
    }



    h3  {

        position: relative;
        cursor: pointer;
        border-bottom: solid 1px #A3A3A3;
        font-family: $font-family-matter;
        //padding: 0.5rem 0.5rem 0.5rem 0;
        padding: 1rem 2rem 1rem 0;
        font-weight: 500;
        color: #e67455;
        font-size: 1rem ;
        @include breakpoint(large) {
            font-size: 1.2rem;
            //padding: 1rem 1rem 1rem 0;
        }

        &.open {
            &:before {
                content: url('/assets/images/icons/prod_info_minus_icon.svg');
                position: absolute;
                //top: 50%;
                right: 1rem;
                //margin-top: -0.75rem;
                
            }
        }
        &.closed {
            &:before {
                content: url('/assets/images/icons/prod_info_plus_icon.svg');
                position: absolute;
                //top: 50%;
                right: 1rem;
                //margin-top: -0.75rem;
                
            }            
        }
        a {

        }

    }


    .content {
        margin-top: 0.5rem;
        @include breakpoint(large) {
            margin-top: 1rem;
        }
        
        margin-bottom: 3rem;    

        .cosmos-grid-contained, .cosmos-cell {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }

        img {
            padding: 1rem 1rem 1rem 0;
            @include breakpoint(medium) {
                padding-top: 0;
            }
        }
    }
}