.award-list {
    margin-bottom: 15rem;
    div.award {
        h2 {
            font-family: $font-family-recife-display;
            font-size: 20px;
            line-height: 26px;
            color: #424242;
            margin: 1rem;
        }
        a {
            color: #0A3039;
            text-decoration: underline;
        }
        display: inline-block;
        width: 370px;
        text-align: center;
        margin: 0 auto;
        @include breakpoint(medium) {
            border-right: solid 1px #D3D3D4;
            width: unset;
        }
        &:last-of-type {
            border-right: none;
        }

        div {
            position: relative;
            padding: 0 0.5rem 3rem;
            &:before {
                background: url('/assets/images/icons/award/painted_circle_B4AEA7.svg') no-repeat;
                content: url('/assets/images/icons/Award_icn.svg');
                position: absolute;
                left: 30px;
                top: -38px;
                width: 87px;
                height: 87px;
                padding: 24px 28px;
            }
        }
    }
}