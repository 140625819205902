// eg: map-get($vibrant-colours, 'ethically-concious')

$vibrant-colours: (
	"ethically-concious": #a9cd34,
	"creative": #dd8f55,
	"experimental": #e67455,
	"lovable": #caa1a6,
	"visionary": #1992a4,
	"discerning": #006eb6,
	"fabulous": #f87089
);

$earth-colours: (
	"ethically-concious": #567e6a,
	"creative": #d1bd71,
	"experimental": #885a61,
	"lovable": #f1d3d5,
	"visionary": #548a7f,
	"discerning": #395775,
	"fabulous": #b4aea7
);

$neutral-colours: (
	"ethically-concious": #88898a,
	"creative": #f0e4d7,
	"experimental": #bba691,
	"lovable": #d0cec9,
	"visionary": #b3c0b5,
	"discerning": #c0cad5,
	"fabulous": #dfe9dc
);

$grey-colours: (
	"grey1": #695b5f,
	"grey2": #7d7f87,
	"grey3": #cbcbd2,
	"grey4": #dad9e0,
	"grey5": #949c9e,
	"grey6": #b7b860,
	"site": #DFDFDF
);

$grey-background: #DFDFDF;