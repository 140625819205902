.header {
    background: white;
    height:56px;
    @include breakpoint(large) {
        height: 88px;
    }
     padding: 0;
    .side {
        width: 32%;

    }
    .center {
        width: 36%;
    }
    .logo {
        margin: auto;
        width: 139px;
        height: 21px;
        @include breakpoint(large) {
            width: 250px;
            height: 37px;
            margin-top:32px;
        }
        
    }

    


}
.menu-wrapper {
    background-color: #DFDFDF;
    .title-bar {
        height: rem-calc(56px);
    }
    ul.vertical {
        background-color: #DFDFDF;
        padding: 25px 35px 20px 35px;

        li {
            &.is-drilldown-submenu-parent {
                >a {
                    &:after {
                        content: url('/assets/images/icons/AF_menu_arrow_right.svg');
                        border-style: none;
                        margin-top: -5px;
                    }
                }
            }
            &.js-drilldown-back {
                >a {
                    font-size: 12px;
                    text-transform: uppercase;
                    &:before {
                        content: url('/assets/images/icons/AF_menu_arrow_left.svg');
                        border-style: none;
                        margin-top: -14px;
                        padding-right: 6px;
                        display: inline-block;
                    }
                }                
            }
        
            a {
                border-bottom: solid 1px white;
                color: #424242;
                letter-spacing: .7px;
                font-size: 16px;
                padding: 13px 0 15px 0;
                background: transparent;
            }
        }
    }
}
#offCanvasMenu {
    background-color: $grey-background;
    z-index: 15;
    &.is-open {
        .header {
            background-color: $grey-background;
        }
    }
}

ul.topmenu {
    margin-bottom: 9px;
    margin-top: 10px;
    li a {
        letter-spacing: .7px;
        font-size: 16px;
        text-align: center;
        position: relative;
        min-width: 102px;
        &.current {
            font-weight: 500;
        }
        &:hover, &.mainlinkactive, &.current {
            

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                /* Use these for keeping aspect ratio */
                //    height: 0;
                //    padding-top: 8%;
                /* Fixed height, stretch the SVG *IF* preserveAspectRatio="none" is added to the viewbox in the svg file */
                height: 7px;
                //sz-index: 1;
            
                // The SVG is added as an SVG background image
                background: url('/assets/images/underlines/home/hero_underline.svg') top left;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                margin: 0 5px;
            }  
        }
    }
}

.megame {
    background-color: $grey-background;

}
#toplinks {
    padding-left: 1rem;
    padding-right: 1rem;
    ul.menu {
        float:right;
    }
    
    a, span.link {
        padding: 13px 4px;
        margin-bottom: 0;
        display: block;
        cursor: pointer;
        line-height: 1;
        @include breakpoint(large) {
            padding: 14px 24px 14px 20px;
            &.basket {
                padding-right: 30px;
            }
        }
        
        font-size: 13px;
    }
    .wishlist {

        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: url('/assets/images/icons/wishlist.svg') no-repeat;
        background-position: 100% 50%;
        &.on {
            background: url('/assets/images/icons/wishlist-on-icn-1.svg') no-repeat;
            background-position: 100% 50%;
        }
    }
    .account {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: url('/assets/images/icons/sign-in.svg') no-repeat;
        background-position: 100% 50%;
        &.public {
            background: url('/assets/images/icons/sign-in-on-icn.svg') no-repeat;
            background-position: 100% 50%;
        }
        &.trade {
            background: url('/assets/images/icons/sign-in-trade-on-icn.svg') no-repeat;
            background-position: 100% 50%;
        }
        &.staff, &.abm {
            background: url('/assets/images/icons/sign-in-staff-on-icn.svg') no-repeat;
            background-position: 100% 50%;
        }        
        &.press {
            background: url('/assets/images/icons/sign-in-press-on-icn.svg') no-repeat;
            background-position: 100% 50%;
        }
    }
    .basket {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        background: url('/assets/images/icons/shopping-basket.svg') no-repeat;
        background-position: 100% 50%;
        &.on {
            background: url('/assets/images/icons/shopping-basket-on-icn.svg') no-repeat;
            background-position: 100% 50%;
        }
    }        
    
}
#mobile-toplinks, #mobile-menu-toplinks, #mobile-menu-toplinks-off, #mobile-menu-toplinks-wl  {
    background-color: $white;
ul.menu {
    @include flex-align('center', 'middle');

    &.right {
        //float:right;
        padding-right: rem-calc(10px);
        @include flex-align('right', 'middle');
        .wishlist {

            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: url('/assets/images/icons/wishlist.svg') no-repeat;
            background-position:  50%;
            &.on {
                background: url('/assets/images/icons/wishlist-on-icn-1.svg') no-repeat;
                background-position: 50%;
            }
        }
        .account {
    
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: url('/assets/images/icons/sign-in.svg') no-repeat;
            background-position: 50%;
            &.on {
                background: url('/assets/images/icons/sign-in-on-icn.svg') no-repeat;
                background-position: 50%;
            }
            &.public {
                background: url('/assets/images/icons/sign-in-on-icn.svg') no-repeat;
                background-position: 50%;
            }
            &.trade, &.abm {
                background: url('/assets/images/icons/sign-in-trade-on-icn.svg') no-repeat;
                background-position: 100% 50%;
            }
            &.press {
                background: url('/assets/images/icons/sign-in-press-on-icn.svg') no-repeat;
                background-position: 50%;
            }
        }
        .basket {
    
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: url('/assets/images/icons/shopping-basket.svg') no-repeat;
            background-position: 50%;
            &.on {
                background: url('/assets/images/icons/shopping-basket-on-icn.svg') no-repeat;
                background-position: 50%;
            }
        }   
        a {
            padding: 25px 13px;
            &.basket {
                padding-right: 18px;
            }
        }
    
    }
    &.left {
        padding-left: rem-calc(10px);
        @include flex-align('left', 'middle');
        .hamburger {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: url('/assets/images/icons/hamburger-menu.svg') no-repeat;
            background-position: 50%;
        }  
        .search {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: url('/assets/images/icons/search.svg') no-repeat;
            background-position: 50%;
        }
        .close {
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            background: url('/assets/images/icons/m-close.svg') no-repeat;
            background-position: 50%;
        }      
        a {
            padding: 25px 16px;
            &.basket {
                padding-right: 18px;
            }
        }  
    }
    

}
}