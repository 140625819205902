.mediacontent {
    margin-bottom: 3rem;

    .icon {
        margin: auto;
        background-repeat: no-repeat;
        background-position-x: center;
        height: 148px;
        position: absolute;
        bottom: -70px;
        left: 0;
        right: 0;
        &.planet {
            background-image: url('/assets/images/planet/planet.svg');
            &:before {
                content: url('/assets/images/planet/AF_Planet_icn.svg');
                position: relative;
                display: block;
                text-align: center;
                padding: 34px 0;
            }
        }
        &.products {
            background-image: url('/assets/images/planet/products.svg');
            cursor: pointer;
            &:before {
                content: url('/assets/images/planet/AF_Products_icn.svg');
                position: relative;
                display: block;
                text-align: center;
                padding: 34px 0;
            }
        }
        &.people {
            background-image: url("/assets/images/planet/people.svg");
            cursor: pointer;
            &:before {
                content: url('/assets/images/planet/AF_People_icn.svg');
                position: relative;
                display: block;
                text-align: center;
                padding: 34px 0;
            }
        }  
        &.forest {
            background-image: url("/assets/images/planet/forest.svg");
            cursor: pointer;
            &:before {
                content: url('/assets/images/planet/AF_Doing_the_right_thing_icon.svg');
                position: relative;
                display: block;
                text-align: center;
                padding: 34px 0;
            }
        }       
    }

    .image-caption {
        display: none !important;
    }

    .eco-badge {
        position: absolute;
        background: white;
        right: 40px;
        bottom: 16px;

        padding: 10px;
        img {
            width: 120px;
            margin-bottom: 0;
        }
        @include breakpoint(medium) {

            img {
                width: 200px;
            }
        }
        @include breakpoint(xlarge) {
            right: 62px;
            bottom: 35px;
            img {
                width: 220px;
            }
        }        
    }

    .cosmos-panel {
        margin: 0 !important;
    }

    h2.large {
        margin-top:5.5rem;
        margin-bottom: 1.5rem;
    }

    img {
        max-height: 500px;
    }

    a.underline {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        &.experimental {
            &:after {
                content: url('/assets/images/underlines/home/orange_link_underline.svg');
                position: absolute;
                left: -10px;
                top: 16px;
            }
        }
    }
    a {
        &:not(.underline) {
            text-decoration: underline;
        }
    }

    .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

    .treestats {
        margin-bottom: 2rem;;
        text-align: right;
    }
}