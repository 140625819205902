.event-list, .advert-template-list {
    margin-bottom: 5rem;
    .cosmos-panel, .cosmos-cell {
        padding: 0 !important;
    }
    div.template {
        background-color: #F7F7F7;
    }
    div.event {
        background-color: white;
    }    
    div.event, div.template {
        padding: 1rem;
        h2 {
            font-family: $font-family-recife-display;
            font-size: 28px;
            line-height: 36px;
            color: #424242;
            letter-spacing: 0.23px;;
            margin: 1rem 0;
        }
        .date {
            text-transform: uppercase;
            font-weight: bold;
        }
        a {
            position: relative;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 13px;
            letter-spacing: 0.5px;
            display: inline-block;
            &:after {
                content: '';
                position: absolute;
                bottom: -12px;
                left: -0.5rem;
                right: -0.5rem;
                /* Use these for keeping aspect ratio */
                //    height: 0;
                //    padding-top: 8%;
                /* Fixed height, stretch the SVG *IF* preserveAspectRatio="none" is added to the viewbox in the svg file */
                height: 10px;
                
            
                // The SVG is added as an SVG background image
                //background: url('/assets/images/underlines/yellow_underline.svg') top left;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &.white {
                color: white;
                &:after {
                    background-image: url('/assets/images/underlines/white.svg');
                }          
            }
            &.neutral-fabulous {
                &:after {
                    background-image: url('/assets/images/underlines/neutral-fabulous.svg');
                }
            }
            &.neutral-discerning {
                &:after {
                    background-image: url('/assets/images/underlines/neutral-discerning.svg');
                }
            }        
            &.neutral-lovable {
                &:after {
                background-image: url('/assets/images/underlines/neutral-lovable.svg');
                }
            }
            &.vibrant-ethical {
                &:after {
                background-image: url('/assets/images/underlines/vibrant-ethical.svg');
                }
            }                
            &.vibrant-experimental {
                &:after {
                background-image: url('/assets/images/underlines/vibrant-ethical.svg');
                }
            }      
            &.vibrant-fabulous {
                &:after {
                background-image: url('/assets/images/underlines/vibrant-fabulous.svg');
                }
            }   
            &.vibrant-discerning {
                &:after {
                background-image: url('/assets/images/underlines/vibrant-discerning.svg');
                }
            }        
            &.vibrant-visionary{
                &:after {
                background-image: url('/assets/images/underlines/vibrant-visionary.svg');
                }
            }                                           
            &.earth-creative {
                &:after {
                background-image: url('/assets/images/underlines/earth-creative.svg');
                }
            } 
            &.earth-lovable {
                &:after {
                background-image: url('/assets/images/underlines/earth-lovable.svg');
                }
            }                 
            &.earth-visionary {
                &:after {
                background-image: url('/assets/images/underlines/earth-visionary.svg');
                }
            }  
            &.earth-discerning {
                &:after {
                background-image: url('/assets/images/underlines/earth-discerning.svg');
                }
            } 
        }
    }
}
