#home {
    position: relative;
    h1 {
        color: map-get($earth-colours, 'visionary');
        font-family: $font-family-recife-display;
        font-weight: bold;
        line-height: 27px;
        font-size: 16px;
        text-align: center;
        padding-top:40px;
        padding-bottom: 40px;
        @include breakpoint(large) {
            font-size: 20px;
        }
    }
    .quicklinks {
        background-color: map-get($earth-colours, 'creative');
        position: fixed;
        bottom: 0;
        height: 70px;
        width: 100%;
        z-index: 20;

        ul.menu { 
            width: 100%;
            li a {
                display: block;
                color: #0A3039;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.9px;
                line-height: 20px;
                size: 9px;
                &.carpets {
                    &:before {
                        content: '';
                        background-image: url('/assets/images/icons/AF_Carpet_icon_blk.svg');
                        background-size: 31px 23px;
                        background-repeat: no-repeat;
                        height: 23px;
                        display: block;
                        background-position: center center;
                    }
                }
                &.rugs {
                    &:before {
                        content: '';
                        background-image: url('/assets/images/icons/AF_Rug_icon_blk.svg');
                        background-size: 24px 19px;
                        background-repeat: no-repeat;
                        height: 23px;
                        display: block;
                        background-position: center center;
                    }
                }       
                &.runners {
                    &:before {
                        content: '';
                        background-image: url('/assets/images/icons/AF_Runner_icon_blk.svg');
                        background-size: 34px 18px;
                        background-repeat: no-repeat;
                        height: 23px;
                        display: block;
                        background-position: center center;
                    }
                }           
            }
        }
    }
}

#mainbanner {
    min-height: calc(100vh - 166px);
    @include breakpoint(medium only) {
        min-height: calc((var(--vh, 1vh) * 100) - 56px - 25px);
    }             
    @include breakpoint(small only) {
        min-height: calc((var(--vh, 1vh) * 100) - 70px - 56px - 25px);
    }

    .title {
       
        position: absolute;
        font-family: $font-family-recife-display;
        font-weight: bold;
        color:white;
        //width: 100%;
        left: 0;
        right: 0;
        text-align: center;
        font-size: rem-calc(50);
        line-height:1rem;
        letter-spacing: 0.4px;
        @include breakpoint(small only) {
            white-space: break-spaces;
            top: 35%;
            padding: 0 0.2rem;
        }

        p {
            display: inline;
            line-height: 1.2;
            @include breakpoint(medium only) {
                line-height: 1.4;
            }
            strong {
                display: inline-block;
                position: relative;
                font-weight: 700 !important;
                &:before {
                    content: '';
                    position: absolute;
                    bottom: -5px;
                    left: -0.5rem;
                    right: -0.5rem;
                    /* Use these for keeping aspect ratio */
                    //    height: 0;
                    //    padding-top: 8%;
                    /* Fixed height, stretch the SVG *IF* preserveAspectRatio="none" is added to the viewbox in the svg file */
                    height: 13px;
                    z-index: -1;
                
                    // The SVG is added as an SVG background image
                    background: url('/assets/images/underlines/home/hero_underline.svg') top left;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }                
            }

        }



        @include breakpoint(large) {
            font-size: rem-calc(62);
            line-height: rem-calc(48);
            letter-spacing: 0.5px;
            &:before {
                left: 0;
                right: unset;
            }
        }
    }
    .orbit-figure {

        img {
            object-fit: cover;
            height: calc(100vh - 141px);
            min-height: 500px;
            width: 100vw;
            @include breakpoint(medium only) {
                height: calc((var(--vh, 1vh) * 100) - 56px);
            }            
            @include breakpoint(small only) {
                height: calc((var(--vh, 1vh) * 100) - 70px - 56px);
            }
        }

        &.head-banner {
            img {
                height: calc(100vh - 141px - 25px);
            
                @include breakpoint(medium only) {
                    height: calc((var(--vh, 1vh) * 100) - 56px - 25px);
                }             
                @include breakpoint(small only) {
                    height: calc((var(--vh, 1vh) * 100) - 70px - 56px - 25px);
                }
            }
        }
    }




    

    .orbit-caption {
        background-image: url('/assets/images/other/brush.png');
        background-position: -83px 2px;
        background-repeat: no-repeat;
        width: 531px;
        background-color: transparent;
        height: 209px;
        color: #424242;
        font-size: 14px;

        @include breakpoint(medium only) {
            bottom: -40px;
        }

        @include breakpoint(small only) {
            height: 165px;
            background-position: -184px 12px;
        }

        p {
            //padding: 63px 72px 78px 60px;
            padding: 50px 0 0 0;
            line-height: 20px;
            width: 350px;
            a {
                text-decoration: bold;
                letter-spacing: 1px;
                line-height: 30px;
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: underline;

            }
            @include breakpoint(medium only) {
                padding-top: 44px;
                padding-left: 27px;
            }
            @include breakpoint(medium) {
                width: 100%;
                padding-right: 78px;
            }   
            @include breakpoint(large) {
                padding-top: 63px;
                padding-left: 46px
            }
        }
    }

    .orbit-bullets {
        position: absolute;
        bottom: 20px;
        left: 11px;
        button {
            width: 0.7rem;
            height: 0.7rem;
            margin: 0.5rem 0.3rem;
            border-radius: 50%;
            background-color: #A3A3A3;
            &.is-active {
                background-color: #5B5B5B;
            }
        }
        @include breakpoint(medium only) {
            bottom: 2px;
            left: 37px;
        }    
        @include breakpoint(large) {
            padding-left: 46px
        }           
    }

    .homebannernav {
        background-color: green;
    }


}
.homelanding {

    &.head {
        padding-top: 2rem;
        padding-bottom: 1rem;
        @include breakpoint(large) {
            padding-top: 3rem;
        }
        img {
            height: 23px; 
            @include breakpoint(large) {
                height: 37px
            }
        }
    }
    text-align: center;
    padding: 0;
    @include breakpoint (medium) {
        padding: 1rem 2rem;
    }
    
    .pullquote {
        text-align: left;
        font-size: 22px;
        letter-spacing: 0.5px;
        line-height: 28px;
        font-family: $font-family-recife-display;
        padding-bottom: 2.5rem;
        color: white;
        font-weight: bold;
        padding: 1rem;
        @include breakpoint(medium) {
            font-size: 32px;
            line-height: 38px;
            //padding: 48px 0 0;
        }        
        @include breakpoint(large) {
            font-size: 36px;
            line-height: 44px;
            //padding: 48px 0 0;
        }
    }
    p {
        line-height: 20px;
        font-size: 14px;

        @include breakpoint(large) {
            font-size: 16px;
            line-height: 24px;
        }
    }
    background-color: map-get($earth-colours, 'experimental');
    color: white;
    &.links {
        
        .slick-list {
            margin: 0 50px;
        }
        .slick-slide {
            /*>img {
                width: 275px;
                margin: auto;
            }*/
            .cosmos-cell {
                @include breakpoint(medium) {
                    max-width: 400px !important;
                } 
            }
        }
        .slick-prev {
            left: 15px;
            &:before {
                content: url('/assets/images/icons/PPP_arrow_left.svg');
                height: 26px;
                width: 14px;
            }
        }
        .slick-next {
            right: 15px;
            &:before {
                content: url('/assets/images/icons/PPP_arrow_right.svg');
                height: 26px;
                width: 14px;
            }
        }
    }
}


.blog {
    background-color: map-get($neutral-colours, 'experimental');
    color: white;
    
    h2 {
        font-family: $font-family-rollerscript-smooth;
        margin-top: 3rem;
        font-weight: normal;
        color: white;
        font-size: 42px;
        text-align: center;
        line-height: 72px;
        @include breakpoint(large) {
            font-size: 64px;
            
        }
    }
    &.posts {
        padding-top:1rem;
        @include breakpoint(large) {
            padding-top:3rem;
        }
        
    }
    .blog-post {
        margin: 0 auto 2rem;
        max-width: 305px;
        @include breakpoint(medium) {
            max-width: unset;
            border-right: 1px #D3D3D4 solid;
            margin: 0;
            &:last-of-type {
                border-right: none;
            }
        }
        a {
            display: block;
        }
        h3 {
            a {
                display: block;
                margin: 1rem 0;
                color: white;
                font-size:20px;
                font-family: $font-family-recife-display;
                font-weight: bold;
                letter-spacing: 0.5px;
                line-height: 26px;
                @include breakpoint(large) {
                    font-size:24px;
                }
            }
        }
        color: $body-font-color;
    }
    &.footer {
        padding: 0 0 2rem 0;
        @include breakpoint(large) {
            padding: 4rem 0;
        }
        a {
            color: white;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.5px;
            &:after {
                content: url('/assets/images/underlines/home/brown_link_underline.svg');
                display: block;
            }
        }
    }
}
.trends {
    .cosmos-grid {
        max-width: unset;
    }
    h2 {
        font-family: $font-family-rollerscript-smooth;
        font-weight: normal;
        color: map-get($vibrant-colours, 'experimental');
        font-size: 42px;
        padding-top: 2rem;
        padding-bottom: 1rem;
        @include breakpoint(large) {
            font-size:72px;
        }
    }

    #trend-scroller {
        overflow: hidden;
        width: 100vw;
    }

    
    padding: 2rem 0 0;
    .trend {

        img {
            //max-width: 300px;
            @include breakpoint(medium) {
                //max-width: unset;
            }
        }
        a {
            text-decoration: none; 
            padding: 0.4rem 0;
            font-weight: 500;
            display: block;
            &:hover {
                text-decoration: underline;

            }
        }
    }

    .slick-slide {
        margin: 0 1rem;
    }
    .slick-list {
        margin: 0 38px;
        @include breakpoint(medium) {
            margin: 0 85px;
        }
    }
    .slick-prev {
        left: -56px;
        z-index: 50;
        height: 111px;
        width: 111px;
        background-image: url('/assets/images/buttons/Trends_circle_left.svg');
        background-size: 111px;
        background-repeat: no-repeat;
        &:before {
            content: url('/assets/images/icons/arrow_left_dk.svg');
            position: absolute;
            right: 35px;
            top: 46px;

        }
    }
    .slick-next {
        right: -44px;
        z-index: 50;
        height: 111px;
        width: 111px;
        background-image: url('/assets/images/buttons/Trends_circle_left.svg');
        background-size: 111px;
        &:before {
            content: url('/assets/images/icons/arrow_right_dk.svg');
            position: absolute;
            left: 35px;
            top: 46px;
        }
    }
    &.bottom {
        margin-bottom: 2rem;
        a {
            text-transform: uppercase;
            letter-spacing:0.5px;
            font-weight: 500;
            font-size: 13px;
            &:after {
                content: url('/assets/images/underlines/home/AF_underline_trends.svg');
                display: block;
            }
        }
    }
}
