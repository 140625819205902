#rich-content {
    padding-bottom: 10rem;
    //lots of nice padding for a rich page
    .cosmos-panel {
       
        //margin: 2rem auto !important;
        //@include breakpoint(large) {
            margin: 3rem auto;
        //}
        &:first-of-type {
            margin-top: 0;
        }
        
        &.captioned {
            margin-bottom: 0!important;
        }
        &.caption {
            margin-top: 0!important;
            @include breakpoint(large) {
                margin-top:1rem!important;
            }
        }

    }

    h1 {
        display: inline-block;
    }

    h2 {
        font-size: 28px;
        line-height: 47px;   
        color: #424242;
        &.large {
            text-align: center;
            font-size: 48px;
        }
    }

    h3 {
        color: $body-font-color;
    }

    strong {
        font-weight: 500;
    }

    .pageimage {
        h2 {
            color: white;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-weight: 500;
        }
    }

    .maxwidth {
        img {
            width: 100%;
        }
    }
/*
    .pullquote {
        @include breakpoint(medium) {
            padding: 0 1.5rem;
        }        
        @include breakpoint(large) {
            padding: 0 3rem;
        }
        @include breakpoint(xlarge) {
            padding: 0 5rem;
        }        
    }*/

    .mediumbold {
        font-size: 24px !important;
        line-height: 32px !important;
        @include breakpoint(large) {
            font-size: 28px !important;
            line-height: 38px !important;
        }
        margin-bottom: 0;
    }

    .largebold {
        font-size: 34px !important;
        line-height: 42px !important;
        @include breakpoint(large) {
            margin-right: 3rem;
            margin-left: 3rem;
            font-size: 48px !important;
            line-height: 62px !important;
        }
    }

    img {
        margin-bottom: 1rem;
        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }

    .image-caption {
        display: block;
        padding: 0.5rem 0;
        font-size: 14px;
        font-style: italic;
    }


}