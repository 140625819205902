//folderlist of blocks

#colourblock {
    
   
    h1  {
        img {
        width: 100%;
        height:  100%;
        padding: 2px 20px;
        }
    }

    h2 {
        //default to dark
        color: $body-font-color;
        
        &.light {
            color: $white;
        }
    }


    h3 {
        font-family: $font-family-matter;
        color: $body-font-color;
        font-size: 1rem;
        @include breakpoint(medium) {
            font-size: rem-calc(20);
        }
        
        font-weight: 500;
        padding-bottom: 1rem;
    }

    .light {
        color: white;
    }


    .cosmos-panel {
        padding: 0;
    }
    .cosmos-grid {
        .cosmos-cell {
            padding: 0;
            margin: 0 auto !important;
        }
    }

    .panel {
        padding: 1rem;
        text-align: left; //always left align on small
        background-color: map-get($neutral-colours, 'lovable'); //default
        @include breakpoint(medium) {
            .align-right {
                text-align: right;
            }
        }
        img {
            @include flex-align('center', 'middle');
            width: unset !important;
        }
    }

    .intro {
        padding-top: 2.1rem;
        padding-bottom: 1rem;
    }

    .img-container {
        height:100%;
        overflow: hidden;
        max-height: 400px;
        @include breakpoint(large) {
            max-height: 537px;
        }
        img {
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%, -50%);
            position: relative;
            left: 50%;
            top: 50%;
            max-width: unset;
        }
    }

    .button-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .circle {
        position: relative;
        &.earth-fabulous {
            background-image: url('/assets/images/buttons/palette/circle/paint-circle-earth-fabulous.svg');
            background-repeat: no-repeat;
            height: 111px;
            width: 111px;
            display: inline-block;
        }
        img {
            margin: auto;
            display: flex;
            padding: 2rem 1.6rem;
            margin-top: 50%;
            transform: translateY(-50%);
        }

    }

/*
    a {
        font-weight: 400;
        &.paint-back {
            
            position: relative;
            img {
                position: relative;
            }
            span {
                position: absolute;
                text-align: center;
                display: inline-block;
                width: 100%;
                left: 0;
                top:0;
                &.light {
                    color: white;
                }
            }
        }
    }*/

}
