.press-list {
    margin-bottom: 10rem;
    h2 {
        font-size: 20px;
        @include breakpoint(medium) {
            font-size: 28px;
        }
        color: #0A3039;
        
        line-height: 32px;
        margin: 1rem 0;
    }
    .search-holder {
        p {
            margin-bottom: 0;
            line-height: 2.5rem;
        }
    }
    a {
        color: map-get($vibrant-colours, 'experimental');
        text-decoration: underline;
    }
}
