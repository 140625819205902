.richlink {
    .tool {
        .img-container {
            
            margin-bottom: 1rem;
            max-height: 303px;
            overflow: hidden;
            position: relative;
            text-align: center;
            img {
                max-width: unset !important;
                width: unset !important;
                @include flex-align('center', 'bottom');
            }
            
            @include breakpoint(small only) {
                padding: 0 1rem;
            }

            @include breakpoint(large) {
                max-height: unset;
            }
            .content {
                img {
                    height: 119px;
                    margin: 17px 17px 5px;
                }
                color: white;
                position: absolute;
                margin-left: auto;
                margin-right: auto;
                top:12px;
                left: 0;
                right: 0;
                width: 80%;
                text-align: center;
                font-size: 14px;

                @include breakpoint(medium) {
                    top:12px;
                    left: 0;
                    right: 0;
                    width: 95%;
                }
                @include breakpoint(large) {
                    width: 45%;
                    left: 45px;
                    right: -37%;
                    img {
                        height: 156px;
                    }
                    top: 85px;
                    p {
                    min-height: 68px;
                    }
                }

                a.paint {
                    color: white;
                    width: 184px;
                    height: 45px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-size: 100% 46px;
                    line-height: 44px;
                    font-weight: 500;
                }
            }
        }
        &.smmr {
            @include breakpoint(medium) {
                padding-right: 0.5rem;
            }
            .content a.paint {
                background-image: url(/assets/images/buttons/green_brush_button.svg);
            }
        }    
        &.mmar {
            @include breakpoint(medium) {
                padding-left: 0.5rem;
            }
            .content a.paint {
                background-image: url(/assets/images/buttons/orange_brush_button.svg);
            }
        } 
    }
    &.landing {
        position: relative;
        padding-bottom: 6rem;

        h2 {
            font-family: $font-family-rollerscript-smooth;
            font-weight: normal;
            color: white;
            font-size: 28px;
            @include breakpoint(large) {
                font-size: 36px;
            }
        }
        p {
            max-width: 400px;
            margin: 0.3rem auto 2rem;
        }
        .top {
            img {
                margin: auto;
            }
            position: relative;
            margin-bottom: 4rem;
            .icon {
                
                background-repeat: no-repeat;
                background-position: center center;
                height: 143px;
                width:143px;
                position: absolute;
                bottom: -65px;
                width: 100%;
                img {
                    position: relative;
                    padding: 2rem;
                    margin: auto;
                }
            }  
        }
        .bottom {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            a {
                text-transform: uppercase;
                color: white;
                letter-spacing:0.5px;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .products {
            .top {
                .icon {
                    background-image: url('/assets/images/buttons/orange-circle-brush.svg');
                }
            }
            &.bottom {
                a {
                    &:after {
                        content: url('/assets/images/underlines/home/orange_link_underline.svg');
                        display: block;
                    }
                }
            }
        }
        .people {
            .top {
                .icon {
                    background-image: url('/assets/images/buttons/pink-circle-brush.svg');
                }
            }  
            &.bottom {
                a {
                    &:after {
                        content: url('/assets/images/underlines/home/pink_link_underline.svg');
                        display: block;
                    }
                }
            }
        }
        .planet {
            .top {
                .icon {
                    background-image: url('/assets/images/buttons/green-circle-brush.svg');
                }
            }
            &.bottom {
                a {
                    &:after {
                        content: url('/assets/images/underlines/home/green_link_underline.svg');
                        display: block;
                    }
                }
            }  
        }
    }   
}
.trend {
    //float: left;
    


    font-size: 14px;
    text-align: center;
    @include breakpoint(medium) {
        text-align: left;
    }
    a {
        font-size: 14px;
        text-decoration: none;
    }

    .text {
        @include breakpoint(medium) {
            text-align: left;
        }
      

        margin: auto;
    }
    h3, h3 a {
        font-family: $font-family-rollerscript-smooth;
        color: map-get($neutral-colours, 'visionary');
        font-size: 32px;
        font-weight: normal;
        margin: 1rem 0 0.5rem;
        text-decoration: none;
    }
    img {
        width: 300px;
    }
    @include breakpoint (medium) {
        text-align: left;
        .text {
            text-align: left;
            max-width: unset;
            margin: unset;
        }
        img {
            width: unset;
        }
    }
} 