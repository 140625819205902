#megamenu-container {
    background-color: white;
    height: 58px;
    .megamenu {
        position: absolute;
        width: 100% !important;
        max-width: 100%;
        margin: 0 auto !important;
        left: 0% !important;
        right: 0% !important;
        border: 0;
        z-index: 550;
        .inner {
            //min-height: 500px;
            padding: 46px 33px;
            background-color: $grey-background;
            &.menucolor7 {
                background-color: map-get($neutral-colours, 'fabulous');
            }
            position: relative;
            .megatitle {
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 0.8px;
                font-size:12px;
                line-height: 33px;
                display: inline-block;
                padding-bottom: 18px;
                position: relative;
                &.megaheader {
                    font-weight: 500;
                    &:before{
                        content: "";
                        position: absolute;
                        bottom: 14px;
                        left: -12px;
                        right: -16px;
                        height: 7px;
                        z-index: 2;
                        background: url(/assets/images/underlines/home/hero_underline.svg) 0 0;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        margin: 0 5px;
                        padding: 0 2rem;
                    }
                }
            }
            .inspire {
                a {
                    font-size: 14px;

                }
                p a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            ul {
                li a.parent {
                    text-decoration: none !important;
                    &:after {
                        content: url('/assets/images/icons/AF_menu_arrow_right.svg');
                        border-style: none;
                        display: inline-block;
                        right: 0;
                        position: absolute;
                    }
                }
                
                margin-left:0;
                li {
                    a {
                        border-bottom: solid 1px white;
                        color: #424242;
                        font-size: 14px;
                        padding: 0;
                        line-height: 41px;
                        
                        display: block;
                        &:hover, &:active, &.current {
                            text-decoration: underline;
                            font-weight: 500;
                        }
                    }
                }
            }
            /*
            .tip-container {
                width: 253px;
                height: 150px;
                overflow: hidden;
                display: inline-block;
            }*/

            .button {
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center center;
                background-origin: padding-box;
                padding: 1rem 0.8rem;
                &.close {
                    background-image: url('/assets/images/icons/m-close.svg');
                    background-size: 14px, 14px, contain;
                    position: absolute;
                    right: 28px;
                    top: -29px;
                }
            }
        }
    }
}
