#instagram, #insta-follow {

    background-color: #F2F2F2;
    padding-bottom: 1rem;
    font-size: 14px;
    .inner {
        padding: 3rem 0 1.5rem;
    }
    h2 {
        font-size: 32px;
        font-weight: bold;
        font-family: $font-family-recife-display;
        color: map-get($vibrant-colours, 'creative') !important;
        line-height: 50px;
        display: block;
        text-align: center;
        letter-spacing: 0.5px;
        padding-bottom: 1rem;
        @include breakpoint(large) {
            font-size: 48px;
            letter-spacing: 0.7px;
        }
    }
    p.count {
        margin: 1rem 0 0.5rem;
        font-size: rem-calc(14); 
        img {
            margin: 0 0 4px;
        }
    }
    a {
        height: 132px;
        width: 131px;
        margin-top: 1rem;
        &.insta {
            background-image: url('/assets/images/icons/AF_instagram-icn-white.svg'), url('/assets/images/buttons/insta-circle.svg');
            background-size: 40px 40px,131px 132px;
            
            background-color: transparent;
            background-repeat: no-repeat, no-repeat;
            background-position: center 33px, center center;

            text-transform: uppercase;
            position: relative;
            color: transparent;

            &:before {
                content: 'follow us';
                color: white;
                font-weight: 500;
                letter-spacing: 0.7px;
                font-size: 10px;
                position: absolute;
                top:80px;
                left:0;
                right:0;
            }
        }

    }
}